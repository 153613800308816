require("turbolinks").start()
require("jquery")
require("jquery-ui")
require("jstree")
import "jquery"
import $ from "jquery"
require("bootstrap");
require('bootstrap-datepicker')
// import 'jquery-ui/ui/widgets/autocomplete.js'
// import autocomplete from 'autocomplete.js/dist/autocomplete.jquery.js';
require("custom/grc/organization")
require("custom/grc/map_services")
import 'select2/dist/js/select2.full.js'
import 'select2/dist/css/select2.css'
import * as bootstrapValidator from 'bootstrapvalidator/dist/js/bootstrapValidator.js';
require("parsleyjs")

$(document).ready(function(){
	$('.common_pincode').select2({
		width: "100%!important",
	    // placeholder: 'Pincode',
	    minimumInputLength: 3,
	    ajax: {
	      type: "get",
	      dataType: "script",
	      url: "/get_pincode_details",
	      delay: 500,
	      data: function(params){
	        var ticket_id = "1142536";
	        params.m = this
	        return{
	          pincode: params.term, ticket_id: ticket_id
	        };
	      },
	      processResults: function(data, params) {
	        if (data.length != 2){
	          $("#pincode-notfound").modal('hide');
	          var myResults;
	          myResults = void 0;
	          myResults = []
	          $.each(JSON.parse(data) , function(index, val) {
	            myResults.push({
	              id: val.id,
	              text: val.name
	            });
	          });
	          return {
	            results: myResults
	          };
	        }
	        else{
	          var user_type = $("#current_user_type").val();
	          if (user_type != "agent"){
	            $("#pincode-notfound").modal('show');
	            var $select_district = params.m.parent().parent().find('.common_city');
	            var $select_country = params.m.parent().parent().find('.common_country');
	            var $select_state = params.m.parent().parent().find('.common_state');
	            $select_district.val("");
	            $select_country.val("");
	            $select_state.val("");
	          }
	          else{
	            var pincode_value  = $(this).closest('form').find('.common_pincode').val();
	            var city_value = params.m.parent().parent().find('.common_city').val();
	            var district_value = params.m.parent().parent().find('.common_district').val();
	            var country_value = params.m.parent().parent().find('.common_country').val();
	            var state_value = params.m.parent().parent().find('.common_state').val();
	            $("#dsc-pincode-edit .gst_edit_pincode").val(pincode_value);
	            $("#dsc-pincode-edit .gst_edit_district").val(district_value);
	            $("#dsc-pincode-edit .gst_edit_city").val(city_value);
	            $("#dsc-pincode-edit .gst_edit_state").val(state_value);
	            $("#dsc-pincode-edit .gst_edit_country").val(country_value);
	            var id  = params.m.parent().parent().find('.dsc_conversation_id').val();
	            $("#dsc-pincode-edit .conversation_id").val(id);
	            $("#dsc-pincode-edit").modal('show');
	          }
	        }
	      }
	    }
  	});

  	$('.city_popup_select').select2({
	    placeholder: 'Select State',
	    minimumInputLength: -1,
	    ajax: {
	      url: '/grc_list_state',
	      dataType: 'json',
	      delay: 500,
	      data: function(term, page) {
	        return {
	          q: term,
	          max: 10
	        };
	      },
	      processResults: function(data) {
	       // console.log(data);
	        var myResults;
	        myResults = void 0;
	        myResults = [];
	        $.each(data, function(index, item) {
	          myResults.push({
	            id: item.id,
	            text: item.name
	          });
	        });
	        return {
	          results: myResults
	        };
	      }
	    }
	});

	$('.common_pincode').on('select2:select', function (e) {
//	console.log(e.params.data);
    var data = e.params.data;
    var $t = $(this);
    var pincode_details = data["text"].split("-")[1].split(", ");
   // console.log($t.parent().parent().parent());
    var $select_city = $t.parent().parent().parent().parent().parent().find('.common_city');
    var $select_country = $t.parent().parent().parent().parent().parent().find('.common_country');
    var $select_state = $t.parent().parent().parent().parent().parent().find('.common_state');
    var $select_district = $t.parent().parent().parent().parent().parent().find('.common_district');
    $select_state.val(pincode_details[2]);
    $select_district.val(pincode_details[1]);
    $select_city.val(pincode_details[0]);
    $select_country.val("India");
    });
});
