// require("turbolinks").start()
require("jquery")
require("jquery-ui")
require("jstree")
import "jquery"
import $ from "jquery"
// require('bootstrap-datepicker')
// import 'eonasdan-bootstrap-datetimepicker';
// import datetimepicker from 'eonasdan-bootstrap-datetimepicker';
// require("eonasdan-bootstrap-datetimepicker")
import 'select2/dist/js/select2.full.js'
import 'select2/dist/css/select2.css'
import * as bootstrapValidator from 'bootstrapvalidator/dist/js/bootstrapValidator.js';
require("parsleyjs")
require("custom/grc/dashboard_page")
require("custom/grc/dashboard_page_home")
// import 'https://qe-assets-crm.vakilsearch.com/js/ckeditor.js'
// require("@ckeditor/ckeditor5-build-classic")
// require("moment/locale/ja")
require("custom/datetimepicker")
import select2 from 'select2'
// require("bootstrap.min.js")
require("custom/multifile")
require("custom/grc/map_services")
// require("custom/editorck")
// window.ClassicEditor = require('@ckeditor/ckeditor5-build-classic');
// $(document).ready(function(){
// 	console.log("ClassicEditor"+ ClassicEditor)
//   // ClassicEditor.create($('#edit_about_text').get()[0]);
// });

$(window).ajaxStart(function(){
  $("#overlayDiv").show();
});

$(window).ajaxStop(function(){
  $("#overlayDiv").hide();
});