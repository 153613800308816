import "jquery"
import $ from "jquery"
$(document).ready(function(){
	function OrganizationMap(){
		$('.organization_mapping').click(function(){
			var ticket_id = $(this).find(":selected").attr('data-ticket-id');
			var service_id = $(this).find(":selected").attr('data-service-id');
			var organization_id = $(this).find(":selected").attr('value')
			var length = $('.organization_mapping').length
			var mapping_data = {}
			var ex_organization_ids = []
			for(var i=0; i<=(length - 1); i++){
				var ex_ticket_id = $('.organization_mapping')[i].attributes['data-ticket-id'].value
				var ex_service_id = $('.organization_mapping')[i].attributes['data-service-id'].value
				var ex_organization_id = $('.organization_mapping')[i].value
				if (ex_organization_id != ''){
					if (mapping_data[ex_organization_id] == undefined){
						mapping_data[ex_organization_id] = []
					}
					if (!(["327", "61", "62", "192", "39", "43", "44", "49", "50", "35", "36", "51", "47", "173", "226", "223", "224", "38", "48", "37", "52", "53"].includes(ex_service_id)))
					{
						if (mapping_data[ex_organization_id].includes(ex_service_id)){
							var message = "You have already linked this service to a business account."
							$("#organization_tool_"+ticket_id).css('display', 'block')
							$("#organization_tool_content_"+ticket_id).html(message)
							$('.submit_map_services').attr('disabled', 'disabled')
							return false;
						}
					}
					mapping_data[ex_organization_id].push(ex_service_id)
					ex_organization_ids.push(ex_organization_id)
				}
			}
			// for(var i=0; i<=(ex_organization_ids.length - 1); i++){
			// 	mapping_data[i]
			// }
				
			$("#organization_tool_"+ticket_id).css('display', 'none')
			var email = "";
			var current_url = window.location.href;
			var params = current_url.split("?")[1];
			if ((params != "") && (params != undefined)){
			email = params.split("=")[1];
			}
			if ($(this).find(":selected").text() == 'Add Business'){
				$(this).val('').trigger('change');
				// if (ticket_owner_id == "" || (ticket_owner_id == undefined)){
					$("#add_business").css("display", "block");
					var payload = {};
					// var url = '/add_organization_render_popup'
					// RenderNextComponent(payload, url);
					var current_url = window.location.href;
					if (current_url.includes("dashboard_page")){
						var url = "/grc/add-organization?" + "dashboard_page"
					}else {
						var url = "/grc/add-organization?" + "map_service&email="+email
					}
        			window.location.href = url;
				// }
				// else{
					// return false;
				// }
			}
			else if ($(this).find(":selected").text() == "Select Business")
			{
				var length = $('.organization_mapping').length
				var mapping_data = {}
				var ex_organization_ids = []
				for(var i=0; i<=(length - 1); i++){
					var ex_ticket_id = $('.organization_mapping')[i].attributes['data-ticket-id'].value
					var ex_service_id = $('.organization_mapping')[i].attributes['data-service-id'].value
					var ex_organization_id = $('.organization_mapping')[i].value
					if (ex_organization_id != ''){
						if (mapping_data[ex_organization_id] == undefined){
							mapping_data[ex_organization_id] = []
						}
						if (!(["327", "61", "62", "192", "39", "43", "44", "49", "50", "35", "36", "51", "47", "173", "226", "223", "224", "38", "48", "37", "52", "53"].includes(ex_service_id)))
						{
							if (mapping_data[ex_organization_id].includes(ex_service_id)){
								var message = "You have already linked this service to a business account."
								$("#organization_tool_"+ticket_id).css('display', 'block')
								$("#organization_tool_content_"+ticket_id).html(message)
								$('.submit_map_services').attr('disabled', 'disabled')
							}
						}
						mapping_data[ex_organization_id].push(ex_service_id)
						ex_organization_ids.push(ex_organization_id)
					}
				}
				if (ex_organization_ids.length > 0)
				{
					$('.submit_map_services').removeAttr('disabled')
				}
				else{
					$('.submit_map_services').attr('disabled', 'disabled')
				}
			}
			else{
				var payload = {};
				payload['attributes'] = {};
				payload['attributes']['service_id'] = service_id;
				payload['attributes']['ticket_id'] = ticket_id;
				payload['attributes']['organization_id'] = organization_id;
				// payload['ticket_owner_id'] = "";
				var current_url = window.location.href;
				var params = current_url.split("?")[1];
				// if ((params != "") && (params != undefined)){
					// payload['ticket_owner_id'] = params.split("=")[1];
				// }
				var header = {};
				// header["key"] = "e7e7a52397d57a5cd126328db7793009";
				// header["token"] = $('.authentication_token').attr('value');
				var vs_api_url = $('.environment_url').attr('value');
				var url = vs_api_url+"/api/v1/grc/validate_organization";
				$.ajax({
					type: "post",
					dataType: 'json',
					url: '/get_grc_ajax_data',
        			data: {payload: payload, url: url},
					success: function (data) {
						if (data['status'] == 'true'){
							if (($('.tool:visible').length) == 0){
								$('.submit_map_services').removeAttr('disabled')
							}
							$('.submit_map_services').removeAttr('disabled')
						}
						else{
							var ticket_id = data['ticket_id']
							var message = data['message']
							$("#organization_tool_"+ticket_id).css('display', 'block')
							$("#organization_tool_content_"+ticket_id).html(message)
							$('.submit_map_services').attr('disabled', 'disabled')
						}
					},
					error: function(xhr,status,error){
						
					}
				});
			}
		});
		
		$('.add_organization_button').click(function(){
			$("#add_business").css("display", "block");
			var payload = {}
			// var url = '/add_organization_render_popup'
			RenderNextComponent(payload, url);
			var url = "/grc/add-organization?" + "dashboard_page" 
        	window.location.href = url;
		});
	}

	function MapServices(){
		$('.submit_map_service').click(function(){
			// grc/map_services
			var length = $('.organization_mapping').length
			var payload = {}
			payload['attributes'] = []
			for(var i=0; i<=(length - 1); i++){
				var ticket_id = $('.organization_mapping')[i].attributes['data-ticket-id'].value
				var organization_id = $('.organization_mapping')[i].value
				var data = {}
				data['ticket_id'] = ticket_id;
				data['organization_id'] = organization_id;
				payload['attributes'].push(data)
			}
			// payload['ticket_owner_id'] = "";
			var current_url = window.location.href;
			var params = current_url.split("?")[1];
			if ((params != "") && (params != undefined)){
				// payload['ticket_owner_id'] = params.split("=")[1];
			}
			var header = {};
			// header["key"] = "e7e7a52397d57a5cd126328db7793009";
			// header["token"] = $('.authentication_token').attr('value');
			var vs_api_url = $('.environment_url').attr('value');
			var url = vs_api_url+"/api/v1/grc/map_services";
			$.ajax({
				type: "post",
				dataType: 'json',
				url: '/get_grc_ajax_data',
        		data: {payload: JSON.stringify(payload), url: url, method_type: "parse_json"},
				success: function (data) {
					if(data["popup_content"]["message"] == "Services mapped!"){
					  $("#service_map").css("display", "none");
                      $("#service_map_progress h3").text(data["popup_content"]["message"]);
					  $("#service_map_progress p").text(data["popup_content"]["desc"]);
					  $("#service_map_progress").css("display", "block");
					  setTimeout(function(){
						if ((params != "") && (params != undefined)){
							// var redirect_url = data["popup_content"]["action"];
							window.location.href = window.location.origin+"/entity_mapping?"+params;
						}
						else{
							window.location.href = window.location.origin+"/"+data["popup_content"]["action"];
						}}, parseInt(data["popup_content"]["autoclose"]));
					}
				},
				error: function(xhr,status,error){
				}
			});
		});

		window.singleTicketMap = function (event){
			var payload = {}
			payload["single_ticket_mapping"] = "true"
			payload['attributes'] = []
			var ticket_id = event.getAttribute('data-ticket-id')
			var organization_id = event.getAttribute('data-organization-id')
			var data = {}
			data['ticket_id'] = ticket_id;
			data['organization_id'] = organization_id;
			payload['attributes'].push(data)
			var header = {};
			// header["key"] = "e7e7a52397d57a5cd126328db7793009";
			// header["token"] = $('.authentication_token').attr('value');
			var vs_api_url = $('.environment_url').attr('value');
			var url = vs_api_url+"/api/v1/grc/map_services";
			$.ajax({
				type: "post",
				dataType: 'json',
				url: '/get_grc_ajax_data',
        		data: {payload: JSON.stringify(payload), url: url, method_type: "parse_json"},
				success: function (data) {
					if(data["popup_content"]["message"] == "Services mapped!"){
						$("#service_map_confirm").css("display", "none");
						$("#ticket_service_mapped h3").text(data["popup_content"]["message"]);
						$("#ticket_service_mapped p").text(data["popup_content"]["desc"]);
						$("#ticket_service_mapped").css("display", "block");
						setTimeout(function(){ 
						  window.location.href = window.location.origin+"/"+data["popup_content"]["action"];
						}, parseInt(data["popup_content"]["autoclose"]));
					}
					// window.location.href = '/dashboard_page'
				},
				error: function(xhr,status,error){
				}
			});
		}

		// $('.single_service_map').unbind('click').on('click', function(e){
		// 	e.preventDefault();
			
		// });
	}

	function RenderNextComponent(data, url){
		$.ajax({
	      type: "post",
	      dataType: "script",
	      url: url,
	      data: {response: JSON.stringify(data)},
	      success: function (data) {
	      	// AddOrganization();
	      	OrganizationMap();
	      	BusinessType();
	      	confirmServiceMapping();
	      	renderChooseAccount();
	      	MapServices();
			// confirmMapReview();
	      },
	      error: function(xhr,status,error){
	      }
    	});
	}

	function GetMandatoryFields(event){
		if (event.form != null){
			var data = $('#'+event.closest('form').id +' .form-control')
		}
		else{
			var data = $('.form-control')
		}
		var fields_ids_to_validate =[]
		for(var i=0; i<=(data.length - 1); i++){
			if (!(data[i].attributes['data-parsley-required'] == undefined))
			{
				if (data[i].attributes['data-parsley-required'].value == 'true'){
					fields_ids_to_validate.push(data[i].attributes['name'].value)
				}
			}
		}
	return fields_ids_to_validate;
	}

	function BusinessType(){
		$('.business_type').change(function(){
			var value = $(this).val();
			var payload = {};
			payload['tab_identifier'] = 'add_organisation'
			payload['attributes'] = {};
			payload['attributes']['business_type'] = value;
			// $.ajax({
	  //         type: "post",
	  //         dataType: "script",
	  //         url: '/add_organization_render_popup',
	  //         data: payload,
	  //         success: function (data) {
	  //         	SearchCompanyName();
			// 	BusinessType();
	  //           return true;
	  //         },
	  //         error: function(xhr,status,error){
	  //           return false;
	  //         }
	  //       });
	        var url = "/grc/add-organization"
        	window.location.href = url;
		});
	}

	function SearchCompanyName(){
		var business_types = ["pvt","opc","section_8", "llp","foreign","nbfc","nidhi","producer"];
		var current_business_type = $('.business_type').val();
		if (business_types.includes(current_business_type)){
			var header = {};
			// header["key"] = "e7e7a52397d57a5cd126328db7793009";
			// header["token"] = $('.authentication_token').attr('value');
			$(".identification_number").attr('readonly', true);
			var vs_api_url = $('.environment_url').attr('value');
			var url = vs_api_url+"/api/v1/grc/company_name_lookup";
			$('.business_name').select2({
	    	placeholder: 'Please enter 3 or more characters',
		    minimumInputLength: 3,
		    ajax: {
		      type: "POST",
		      dataType: "json",
		      url: '/get_grc_ajax_data',
		      delay: 500,
		      data: function(params){
		      	var keyword = params.term;
		      	var payload = {}
		      	payload['attributes'] = {};
						payload['attributes']['name'] = keyword;
						payload['attributes']['business_type'] = current_business_type;
				var response_data = {};
				response_data["payload"] = payload;
				response_data["url"] = url;
		        return response_data;
		      },
		      processResults: function(data, params) {
		      	Select2ClickeEvent()
				SubmitAction();
		      	var length = data.length
		      	var myResults;
	          myResults = void 0;
	          myResults = []
	          $.each(data , function(index, val) {
	            myResults.push({
	              id: val['cin_no'],
	              text: val['company_name']
	          });
	          });
	          return {
	            results: myResults
	          };
		      }
		    }
		  });
		}
		SubmitAction();
	}

	function Select2ClickeEvent(){
		$('.business_name').on('select2:select', function (e) {
			// var text = $(this).text().split('Select Business Name')[1];
			var text = $(this).find(":selected").text();
			var value = $(this).find(":selected").val();
			//console.log("Company Name :"+text)
			//console.log("CIN :"+value)
			if (value == 'NIL'){
		  		var value = $(this).val();
					var payload = {};
					var id = $('#organization_id').attr('value');
					payload['table_name'] = 'organization';
					payload['attributes'] = {};
					payload['attributes']['id'] = id;
					payload = BuildPayload(payload)
					payload['attributes']['is_company_name_editable'] = true;
					// $.ajax({
		   //        type: "post",
		   //        dataType: "script",
		   //        url: '/add_organization_render_popup',
		   //        data: payload,
		   //        success: function (data) {
					// BusinessType();
					// SubmitAction();
		   //          return true;
		   //        },
		   //        error: function(xhr,status,error){
		   //          return false;
		   //        }
		   //      });
		   var url = "/grc/add-organization"
        	window.location.href = url;
			}
			else{
				$(".identification_number").val(value);
				$(".identification_number").parsley().validate();
			}
 	  });
	}

	function BuildPayload(payload){
		payload['attributes']['business_type'] = $('.business_type').val();
		if ($('.business_name').is('select') == true){
			// payload['attributes']['business_name'] = $('.business_name').text().split('Select Business Name')[1];
			payload['attributes']['business_name'] = $('.business_name').find(":selected").text();
		}
		else{
			payload['attributes']['business_name'] = $('.business_name').val();
		}

		payload['attributes']['identification_number'] = $('.identification_number').val();
		payload['confirm'] = 'true';
		return payload;
	}

	function SubmitAction(){
		$('.submit_button').click(function(){
			var url = $(this).attr('action')
			var success = false;
			var mandatory_values = [];
			if (($(this).text().trim() == 'Continue') || ($(this).text().trim() == 'Save')){
				var mandatory_fields = GetMandatoryFields(this);
				if (mandatory_fields.length > 0){
					for(var i=0; i<=(mandatory_fields.length - 1); i++){
						if ($("."+mandatory_fields[i]).parsley().validate() == true){
							mandatory_values.push(true);
						}
						else{
							mandatory_values.push(false);
						}
					}
					if (mandatory_values.includes(false)){
						success = false;
					}
					else{
						success = true;	
					}
				}
				else{
					success = true;
				}	
			}
			else{
				success = true
			}
			if (success == true)
			{
				var vs_api_url = $('.environment_url').attr('value');
				var url = vs_api_url+'/api/v1'+url
				var payload = {};
				var id = $('#organization_id').attr('value');
				payload['table_name'] = 'organization';
				payload['attributes'] = {};
				payload['attributes']['id'] = id;
				if (($(this).attr('value') == 'continue') || ($(this).attr('value') == 'save'))
				{
					payload = BuildPayload(payload)
				}
				var header = {};
				// header["key"] = "e7e7a52397d57a5cd126328db7793009";
				// header["token"] = $('.authentication_token').attr('value');
				SubmitData(payload, url, header);
			}
			else{
				return false;	
			}
		});
	}

	function SubmitData(payload, url, header){
		//console.log("URL"+url)
		//console.log("PAYLOAD"+payload)
		$.ajax({
	      type: "post",
	      dataType: "json",
		  url: '/get_grc_ajax_data',
          data: {payload: payload, url: url},
	      success: function (data) {
	       	var component_name = data['data'][0]['table_identifier']['tab_identifier']
	      	var id = data['data'][0]['table_identifier']['id']
	      	var message = data['popup_content']['message']
	      	var popup_body = data['popup_content']['desc']
	      	// var redirect_url = '/map_services'
	      	var redirect_url = window.location.href
	      	window.location.href = redirect_url
	      	data['confirm'] = 'true';
	      	// RenderNextComponent(data, redirect_url)
	      },
	      error: function(xhr,status,error){
	      }
    	});
	}
    
	function confirmServiceMapping(){
        $(".submit_map_services").click(function (){
			$("#service_map").css("display", "block");
		});
	}

	function renderChooseAccount(){
		$('.map_service_skip').click(function (){
			var url = $(this).attr('action')
			var current_url = window.location.href;
			var params = current_url.split("?")[1];
			if ((params != "") && (params != undefined)){
				window.location.href = window.location.origin+url+"?"+params
			}
			else{
				window.location.href = window.location.origin+url
			}
			// window.location.href = "/choose_account";
		});
		$('.choose_account_back').click(function (){
			var url = "/grc/map-services";
			var current_url = window.location.href;
			var params = current_url.split("?")[1];
			if ((params != "") && (params != undefined)){
				window.location.href = window.location.origin+url+"?"+params
			}
			else{
				window.location.href = window.location.origin+url
			}
		});
		$(".close_confirmation_popup").click(function(){
			$(this).closest(".password-popupoverlay").css("display", "none");
		});
		$(".choose_account_redirect").click(function(){
			var url = $(this).attr('value');
			var current_url = window.location.href;
			var params = current_url.split("?")[1];
			if ((params != "") && (params != undefined)){
				window.location.href = window.location.origin+url+"?"+params
			}
			else{
				window.location.href = window.location.origin+url
			}
		});
	}

	OrganizationMap();
	MapServices();
	// confirmMapReview();
	confirmServiceMapping();
	renderChooseAccount();
});
window.loadProgress = function(){
  $('.progress-bar').animate(
        {width:'100%'}, 
        {
            duration:7000,
            step: function(now, fx) {
                if(fx.prop == 'width') {
                    $('.sr-only').html(Math.round(now * 100) / 100 + '% Complete');
                }
            }
        }
      );
}