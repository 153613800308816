import "jquery"
import $ from "jquery"
require('bootstrap-datepicker')
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker3.css'
// import 'eonasdan-bootstrap-datetimepicker';
// require("eonasdan-bootstrap-datetimepicker")
// require("custom/multifile")
// import MultiSelector from "custom/multifile"

  window.DashboardPage = function() {
    window.renderPayment = function(type) {
      var dashboard_key = $(".grc_dashboard_key").val();
      // var url = '/grc/payments/'+dashboard_key;
      // RenderNextComponent({}, url);
      var organization_id = $('#selected_organization_value').val();
      var ticket_owner_id = "";
      var current_url = window.location.href;
      var params = current_url.split("?")[1];
      if ((params != "") && (params != undefined) && params.split("=").includes('ticket_owner_id')){
        ticket_owner_id = params.split("=")[1];
      }
      $.ajax({
        url: '/grc/payments/'+dashboard_key,
        type: 'POST',
        dataType: "script",
        data: {type: type, organization_id: organization_id, method_type: "Post", method_name: "payment_detail", action_value: "payment", ticket_owner_id: ticket_owner_id},
        beforeSend: function(){
          $("#overlayDiv").show();
        },
        success: function (data) {
          GlobalClickFunction();
          $("#overlayDiv").hide();
          return false;
        }
      });
    }

    $('#bundle_get_now').click(function(e){
      e.preventDefault();
      var dashboard_key = $(".grc_dashboard_key").val();
      var step = 'bundle_service'
      var payload = {};
      var total_service_ids = [];
      payload['organization_id'] = $('#selected_organization_value').val();
      payload['bundle_name'] = $('#bundle_name').val()
      payload['service_ids'] = [];
      $('.bundle_service_checkbox').filter(':checked').each(function() {
        var service_id = $(this).attr('data-id')
        payload['service_ids'].push(service_id)
        total_service_ids.push(service_id)
      });
      var params = getGrcSourceAndCurrentPage();
      params['selectedServiceIds'] = total_service_ids
      params['bundle_name'] = $('#bundle_name').val()
      params['bundle_count'] = total_service_ids.length
      //   window.vsTrackEvent({
      //   eventName: 'bundle_widget_cta_clicked',
      //   params: params
      // });
      params['Role'] = $('.fibouserrole').val();
      params['Email'] = $('.fibouseremail').val();
      params['Name'] = $('.fibousername').val();
      params['EntityId'] = $('.fiboentityid').val();
      params['Entity_name'] = $('.fiboentityname').val();
      params['Entity_type'] = $('.fiboentitytype').val();
      params['Pagename'] = $('.fiboscreenname').val();
      params['bundle_experiment_name'] = $('#bundle_experiment_name').val();
      window.vsTrackEvent({
          eventName: 'bundle_widget_cta_clicked',
          params: params,
        user: {
          userId: $('.fibocustomerid').val(),
          email: $('.fibouseremail').val(),
          name : $('.fibousername').val()
        }
      }, 'bundle_widget_cta_clicked');

      var url = '/grc/'+step+'/'+dashboard_key;
      RenderNextComponentDashboard(payload, url);
    });

    $('.bundle_service_checkbox').click(function(e){
      e.preventDefault();
      var params = getGrcSourceAndCurrentPage();
      var dashboard_key = $(".grc_dashboard_key").val();
      var step = 'dashboard'
      var payload = {};
      var total_service_ids = [];
      payload['organization_id'] = $('#selected_organization_value').val();
      payload['bundle_name'] = $('#bundle_name').val()
      payload['service_ids'] = [];
      $('.bundle_service_checkbox').filter(':checked').each(function() {
        var service_id = $(this).attr('data-id')
        payload['service_ids'].push(service_id)
        total_service_ids.push(service_id)
      });
      if (total_service_ids.length <= 1){
        alert('Select Minimum Two Services');
        return false;
      }
      else{

        if (this.checked == true){
          var event_name = 'bundle_service_checked';
        }
        else{
          var event_name = 'bundle_service_unchecked';
        }
        
        params['service_name'] = $(this).attr('data-service-name')
        params['bundle_name'] = $('#bundle_name').val()
        params['bundle_count'] = total_service_ids.length
        //   window.vsTrackEvent({
        //   eventName: event_name,
        //   params: params
        // });
        params['Role'] = $('.fibouserrole').val();
        params['Email'] = $('.fibouseremail').val();
        params['Name'] = $('.fibousername').val();
        params['EntityId'] = $('.fiboentityid').val();
        params['Entity_name'] = $('.fiboentityname').val();
        params['Entity_type'] = $('.fiboentitytype').val();
        params['Pagename'] = $('.fiboscreenname').val();
        params['bundle_experiment_name'] = $('#bundle_experiment_name').val();
        window.vsTrackEvent({
            eventName: event_name,
            params: params,
          user: {
            userId: $('.fibocustomerid').val(),
            email: $('.fibouseremail').val(),
            name : $('.fibousername').val()
          }
        }, event_name);

        var url = '/grc/'+step+'/'+dashboard_key;
        RenderNextComponentDashboard(payload, url);
        return false;
      }
      
    });

    $('#bundle_home_page').click(function(e){
      e.preventDefault();
      var dashboard_key = $(".grc_dashboard_key").val();
      var step = 'dashboard'
      var payload = {};
      payload['organization_id'] = $('#selected_organization_value').val();
      payload['bundle_name'] = $('#bundle_name').val()
      payload['service_ids'] = [];
      $('.bundle_service_checkbox').filter(':checked').each(function() {
        var service_id = $(this).attr('data-id')
        payload['service_ids'].push(service_id)
      });
      var url = '/grc/'+step+'/'+dashboard_key;
      RenderNextComponentDashboard(payload, url);
      return false;
    });

  
  $('#lock_my_plan_submit').click(function(e){
    e.preventDefault();
    var dashboard_key = $(".grc_dashboard_key").val();
    var step = 'lock_bundle_service'
    var payload = {};
     var ticket_payload = {};
    payload['organization_id'] = $('#selected_organization_value').val();
    payload['bundle_name'] = $('#bundle_name').val()
    payload['service_ids'] = [];
    var total_service_ids = [];
    var total_service_names = []
    var selected_service_names = []

    $('.new-service-btn[data-selected=true]').each(function() {
      var service_id = $(this).attr('data-id')
      var service_name = $(this).attr('data-service-name')
     // console.log("ID =>"+ service_id)
      total_service_ids.push(service_id)
      selected_service_names.push(service_name)
      total_service_names.push(service_name)
    });

    $('.bundle_service_checkbox').filter(':checked').each(function() {
      var service_id = $(this).attr('data-id')
      var service_name = $(this).attr('data-service-name')
     // console.log("ID =>"+ service_id )
      total_service_ids.push(service_id)
      total_service_names.push(service_name)
    });

    payload['service_ids'] = total_service_ids
    
    var params = getGrcSourceAndCurrentPage();
    var event_name = 'lock_my_plan_clicked'
    params['service_names'] = total_service_names
    params['selected_service_names'] = total_service_names
    params['bundle_name'] = $('#bundle_name').val()
    params['bundle_count'] = total_service_ids.length
    //   window.vsTrackEvent({
    //   eventName: event_name,
    //   params: params
    // });
    params['Role'] = $('.fibouserrole').val();
    params['Email'] = $('.fibouseremail').val();
    params['Name'] = $('.fibousername').val();
    params['EntityId'] = $('.fiboentityid').val();
    params['Entity_name'] = $('.fiboentityname').val();
    params['Entity_type'] = $('.fiboentitytype').val();
    params['Pagename'] = $('.fiboscreenname').val();
    params['bundle_experiment_name'] = $('#bundle_experiment_name').val();
    window.vsTrackEvent({
        eventName: event_name,
        params: params,
      user: {
        userId: $('.fibocustomerid').val(),
        email: $('.fibouseremail').val(),
        name : $('.fibousername').val()
      }
    }, event_name);

    ticket_payload['event_params'] = {}
    ticket_payload['event_params']['page_name'] = params['page_name']
    ticket_payload['event_params']['parent_page'] = ''
    ticket_payload['event_params']['path'] = params['path']
    ticket_payload['event_params']['section_name'] = ''
    ticket_payload['event_params']['source'] = params['source']
    ticket_payload['mWeb'] = false

    ticket_payload['organization_id'] = $('#selected_organization_value').val();
    ticket_payload['ticket_id'] = $("#bundle_ticket_id").val()
    ticket_payload['quotation_params'] = $.parseJSON($("#bundle_quotation_params").val())
    ticket_payload['service_ids'] = total_service_ids

    ticket_payload['ticket_params'] = []
    // var length = $.parseJSON($("#bundle_ticket_params").val()).length
    // for (var i = 0; i <= (length - 1); i++) {
    //     ticket_payload['ticket_params'].push($.parseJSON($.parseJSON($("#bundle_ticket_params").val())[i]))
    // }
    $.ajax({
      type: "POST",
      dataType: "json",
      headers: {token: 'tPiz9vb0vGxATzEEsNciEsPf9GRNcNp6'},
      url: '/api/v1/tickets/bulk_lead_create',
      data: ticket_payload,
      success: function (data) {
       // console.log('Redirection URL =>'+ data['payment_url'])
        var redirection_url = data['payment_url']
        // window.open(redirection_url);
        // window.location.href = redirection_url
        payload['payment_url'] = redirection_url
        var url = '/grc/'+step+'/'+dashboard_key;
        RenderNextComponentDashboard(payload, url);
        return false;
      }
    });
  });

  $("#bundle_lock_my_plan").click(function(e){
    e.preventDefault();
    var dashboard_key = $(".grc_dashboard_key").val();
    var step = 'lock_bundle_service'
    var payload = {};
    payload['organization_id'] = $('#selected_organization_value').val();
    payload['bundle_name'] = $('#bundle_name').val()
    payload['service_ids'] = [];
    $('.bundle_service_checkbox').filter(':checked').each(function() {
      var service_id = $(this).attr('data-id')
      payload['service_ids'].push(service_id)
    });
    
    var url = '/grc/'+step+'/'+dashboard_key;
    RenderNextComponentDashboard(payload, url);
    return false;
  });

  $("#bundle_add_on_service_submit").click(function(e){
    e.preventDefault();
    var length = $('.lock_bundle_service_checkbox').filter(':checked').length
    var total_services = [];
    var selected_service_ids = [];
    var selected_service_names = [];
    var total_service_names = []
    if ($('.new-service-btn[data-selected=true]').length <= 1){
      alert('Select Minimum Two Services')
      return false;
    }

    $('.new-service-btn[data-selected=true]').each(function() {
        var service_id = $(this).attr('data-id')
        total_services.push(service_id)
        var service_name = $(this).attr('data-service-name')
       // console.log("ID =>"+ service_id )
        total_service_names.push(service_name)
    });

    $('.lock_bundle_service_checkbox').filter(':checked').each(function() {
        var service_id = $(this).attr('id')
         var service_name = $(this).attr('data-service-name')
       // console.log("ID =>"+ service_id)
        total_services.push(service_id)
        total_service_names.push(service_name)
        selected_service_names.push(service_name)
    });
    if (selected_service_names.length > 0){
      var params = getGrcSourceAndCurrentPage();
      var event_name = 'bundle_service_added'
      params['service_names'] = selected_service_names
      params['selected_service_names'] = total_service_names
      params['bundle_name'] = $('#bundle_name').val()
      params['bundle_count'] = total_services.length
      //   window.vsTrackEvent({
      //   eventName: event_name,
      //   params: params
      // });
      params['Role'] = $('.fibouserrole').val();
      params['Email'] = $('.fibouseremail').val();
      params['Name'] = $('.fibousername').val();
      params['EntityId'] = $('.fiboentityid').val();
      params['Entity_name'] = $('.fiboentityname').val();
      params['Entity_type'] = $('.fiboentitytype').val();
      params['Pagename'] = $('.fiboscreenname').val();
      params['bundle_experiment_name'] = $('#bundle_experiment_name').val();
      window.vsTrackEvent({
          eventName: event_name,
          params: params,
        user: {
          userId: $('.fibocustomerid').val(),
          email: $('.fibouseremail').val(),
          name : $('.fibousername').val()
        }
      }, event_name);

    }
    

    var dashboard_key = $(".grc_dashboard_key").val();
    var step = 'bundle_service'
    var payload = {};
    payload['organization_id'] = $('#selected_organization_value').val();
    payload['bundle_name'] = $('#bundle_name').val()
    payload['service_ids'] = total_services;
    var url = '/grc/'+step+'/'+dashboard_key;
    RenderNextComponentDashboard(payload, url);
    return false;
  });

  // $('.new-service-btn').click(function(){
  //   var length = $('.lock_bundle_service_checkbox').filter(':checked').length
  //   var total_services = [];
    
  //   if ($('.new-service-btn[data-selected=true]').length <= 1){
  //     alert('Select Minimum Two Services')
  //     return false;
  //   }
  //   $('.new-service-btn[data-selected=true]').each(function() {
  //       var service_id = $(this).attr('data-id')
  //       total_services.push(service_id)                
  //   });

  //   $('.lock_bundle_service_checkbox').filter(':checked').each(function() {
  //       var service_id = $(this).attr('id')
  //      // console.log("ID =>"+ service_id )
  //       total_services.push(service_id)     
  //   });

  //   var dashboard_key = $(".grc_dashboard_key").val();
  //   var step = 'bundle_service'
  //   var payload = {};
  //   payload['organization_id'] = $('#selected_organization_value').val();
  //   payload['bundle_name'] = $('#bundle_name').val()
  //   payload['service_ids'] = total_services;
  //   var url = '/grc/'+step+'/'+dashboard_key;
  //   RenderNextComponentDashboard(payload, url);
  //   return false;
  // });

  $("#pay_bundle_service").click(function(e){
    e.preventDefault();
    var dashboard_key = $(".grc_dashboard_key").val();
    var step = 'bundle_service'
    var payload = {};
    var service_names = [];
    payload['organization_id'] = $('#selected_organization_value').val();
    payload['ticket_id'] = $("#bundle_ticket_id").val()
    payload['quotation_params'] = $.parseJSON($("#bundle_quotation_params").val())
    payload['service_ids'] = $.parseJSON($("#pay_bundle_services").val())
    service_names = $.parseJSON($("#pay_bundle_service_names").val())
    
    var params = getGrcSourceAndCurrentPage();
    var event_name = 'pay_now_cta_clicked'
    params['service_names'] = service_names
    params['selected_service_names'] = service_names
    params['bundle_name'] = $('#bundle_name').val()
    params['total_amount'] = $('#total_amount').text().replace('₹', '')
    params['total_amount_after_discount'] = $('#total_amount_after_discount').text().replace('₹', '')
    //   window.vsTrackEvent({
    //   eventName: event_name,
    //   params: params
    // });
    params['Role'] = $('.fibouserrole').val();
    params['Email'] = $('.fibouseremail').val();
    params['Name'] = $('.fibousername').val();
    params['EntityId'] = $('.fiboentityid').val();
    params['Entity_name'] = $('.fiboentityname').val();
    params['Entity_type'] = $('.fiboentitytype').val();
    params['Pagename'] = $('.fiboscreenname').val();
    params['bundle_experiment_name'] = $('#bundle_experiment_name').val();
    window.vsTrackEvent({
        eventName: event_name,
        params: params,
      user: {
        userId: $('.fibocustomerid').val(),
        email: $('.fibouseremail').val(),
        name : $('.fibousername').val()
      }
    }, event_name);
    var redirection_url = $("#pay_bundle_service").attr('href')
    window.location.href = redirection_url
  });

    window.openComplianceEvent = function(id) {
      var vs_api_url = $('.environment_url').attr('value');
      var url = vs_api_url+"/api/v1/get_compliance_event/"+ id
      var header = {};
      header["key"] = $('#api_key').attr('value');
      header["token"] = $('.authentication_token').attr('value');
      $.ajax({
        type: "get",
        dataType: "json",
        url: '/get_grc_ajax_data',
        data: {payload: {}, url: url},
        success: function (data) {
          $("#compliance_calender_know_more").show();
          var conetnt = BuildComplianceDetail(data)
          $(".compliance-details .header p").html(data['title'])
          $(".compliance-details .description p").html(data['description'])
          $(".details .due-date p").html(data['due_date'])
          $(".deliverable .items ol").html(conetnt['legal_entitys_value'])
          $(".compliance-penalty p").html(data['penalty'])
          var button = data['buttons'][0]
          if (button['value'] == 'view_status'){
            $(".footer div").html("<a href='/grc/ticket-status/'"+button['ticket_id'] +"' target='_blank'><button class='goto_status_dashboard' fta-tid='"+button['ticket_id']+"'>"+ button['text'] +"</button></a>")
          } else if (button['value'] == 'go_to_dashboard') {
            $(".footer div").html("<a href='"+button['action']+"' target='_blank'><button class='goto_dashboard'>"+button['text']+"</button></a>")
          } else if (button['value'] == 'pay_now'){
           $(".footer div").html("<a href='"+button['action'] +"' target='_blank'><button class='pay_now_calender'>"+button['text']+"</button></a>")
          } else {
            $(".footer div").html("<button class='interested_service iminterested' onclick='leadCreatedIntersted("+data['service_id']+", "+data['compliance_event_id']+")' value="+data['service_id']+" id='interested_btn' data-dismiss='modal'>I'm Interested</button>")
          }
        },
        error: function(xhr,status,error){
          return false;
        }
      });
    };

    window.leadCreatedIntersted = function(serviceId, complianceId) {
      var actionType = "I'm Interested"
      LeadCreationPopup(serviceId,actionType,complianceId)
    }

    window.BuildComplianceDetail = function(data){
      var title = data['title']
      var due_date = data['due_date']
      var detail_description = data['description']
      var applicable_for = data['applicable_for']
      var penalty = data['penalty']
      var legal_entitys = data['applicable_for_legal_entity']
      var legal_entitys_value = ""
      for (var i = 0; i <= (legal_entitys.length - 1); i++) {
        var legal_entity = " <li>"+legal_entitys[i]+"</li>"
        legal_entitys_value += legal_entity
      }
      var laws = data['applicable_under_law_service']
      var law_values = ""
      for (var i = 0; i <= (laws.length - 1); i++) {
        var law = " <li>"+laws[i]+"</li>"
        law_values += law
      }
      var service_id = data['service_id']
      var compliance_id = data['compliance_event_id']
      return {legal_entitys_value: legal_entitys_value, law_values: law_values}
      // return "<div class='row' style='padding: 10px 20px 0px 20px;margin: 0;border-bottom: 1px dashed #d6d6d6;'><div class='col-md-9'>  <h3 style='text-align: left;'>"+title+"</h3></div><div class='col-md-3' style='padding:0px;'>  <p style='color:black;margin-top:25px;margin-bottom:0;'>Due date: <span>"+due_date+"</span></p></div>    </div>    <div class='popup-form popup-form-label' style='text-align: left;padding: 0 35px;height: 330px;overflow-y: auto;overflow-x: hidden;'>.calendar .dropdownToggle      <h3 style='font-size: 16px;font-weight: 600;text-align: left; margin: 10px 0;'>Description</h3>      <p style='color: #3d3d3d;margin: 0 0 10px;'>"+ detail_description+"</p>      <div class='row calendar-compliance-popup' style='padding-top: 15px;'>        <div class='col-md-6.calendar .dropdownToggle'>          <h6 style='font-size:16px;'>Applicable for</h6>        </div>        <div class='col-md-6'>          <h6 style='font-size:16px;'>Penalty</h6>        </div>      </div>      <div class='row calendar-compliance-popup' style='padding-bottom: 15px;'>        <div class='col-md-6'>          <p style='color: #3d3d3d;margin: 0 0 10px;'>"+applicable_for+"</p>        </div>        <div class='col-md-6'>          <p style='color: #3d3d3d;margin: 0 0 10px;'>"+ penalty+"</p>        </div>      </div>      <div class='row'>        <div class='col-md-6'>          <h6 style='font-size:16px;'>Legal entity</h6>        </div>        <div class='col-md-6'>          <h6 style='font-size:16px;'>Law</h6>        </div>      </div>      <div class='row'>        <div class='col-md-6'>          <ul style='padding-left:12px'>"+ legal_entitys_value+" </ul>        </div>        <div class='col-md-6'>          <ul style='padding-left:12px'>   "+ law_values +"     </ul>        </div>      </div>       </div>";
    }

    window.renderService = function(service_type) {
      var dashboard_key = $(".grc_dashboard_key").val();
      var url = '/grc/my-services/'+dashboard_key;
      RenderNextComponent({}, url);
    }

    window.renderMyService = function(service_type) {
      var dashboard_key = $(".grc_dashboard_key").val();
      // var url = '/grc/my_services/'+dashboard_key;
      // RenderNextComponent({}, url);
      var organization_id = $('#selected_organization_value').val();
      var ticket_owner_id = "";
      var current_url = window.location.href;
      var params = current_url.split("?")[1];
      if ((params != "") && (params != undefined) && params.split("=").includes('ticket_owner_id')){
        ticket_owner_id = params.split("=")[1];
      }
      $.ajax({
        url: '/grc/my-services/'+dashboard_key,
        type: 'POST',
        dataType: "script",
        data: {type: service_type, organization_id: organization_id, method_type: "Post", method_name: "ticket_card_list", action_value: "ticket_card_list", ticket_owner_id: ticket_owner_id},
        beforeSend: function(){
          $("#overlayDiv").show();
        },
        success: function (data) {
              GlobalClickFunction();
              $("#overlayDiv").hide();
              return false;
        }
      });
    }

    window.searchServiceTicket = function(){
      var dashboard_key = $(".grc_dashboard_key").val();
      var search_value = $('.search_service_name').val();
      var organization_id = $('#selected_organization_value').val();
      var ticket_owner_id = "";
      var current_url = window.location.href;
      var params = current_url.split("?")[1];
      if ((params != "") && (params != undefined) && params.split("=").includes('ticket_owner_id')){
        ticket_owner_id = params.split("=")[1];
      }
      if ((search_value.length >= 3 && event.keyCode === 13) || search_value === ''){
        $(".service-nav-buttons ul li").each(function(){
          if($(this).hasClass("active")) {
            var type = $(this).find('a').attr('href').slice(1);
            $.ajax({
              url: '/grc/my-services/'+dashboard_key,
              type: 'GET',
              dataType: "script",
              data: {q: search_value, type: type, organization_id: organization_id, method_type: "Post", method_name: "ticket_card_list", action_value: "ticket_card_list", ticket_owner_id: ticket_owner_id},
              success: function (data) {
                $("#overlayDiv").hide();
                return false;
              }
            });
          }
        });
      }
    }

    window.searchPaymentTicket = function(){
      var dashboard_key = $(".grc_dashboard_key").val();
      var search_value = $('.service_name').val();
      var organization_id = $('#selected_organization_value').val();
      var ticket_owner_id = "";
      var current_url = window.location.href;
      var params = current_url.split("?")[1];
      if ((params != "") && (params != undefined) && params.split("=").includes('ticket_owner_id')){
        ticket_owner_id = params.split("=")[1];
      }
      if ((search_value.length >= 3 && event.keyCode === 13) || search_value === ''){
        $(".service-nav-buttons ul li").each(function(){
          if($(this).hasClass("active")) {
            var type = $(this).find('a').attr('value');
            $.ajax({
              url: '/grc/payments/'+dashboard_key,
              type: 'GET',
              dataType: "script",
              data: {q: search_value, type: type, organization_id: organization_id, method_type: "Post", method_name: "search_payment_detail", action_value: "payment", ticket_owner_id: ticket_owner_id},
              success: function (data) {
                GlobalClickFunction();
                $("#overlayDiv").hide();
                return false;
              }
            });
          }
        });
      }
    }

    window.renderCompliance = function(selectedType, selectedEntity,selectedDate) {
      var organization_id = $('#selected_organization_value').val();
      var dashboard_key = $(".grc_dashboard_key").val();
      var ticket_owner_id = "";
      var current_url = window.location.href;
      var params = current_url.split("?")[1];
      if ((params != "") && (params != undefined) && params.split("=").includes('ticket_owner_id')){
        ticket_owner_id = params.split("=")[1];
      }
      $.ajax({
        url: '/grc/calendar/'+dashboard_key,
        type: 'GET',
        dataType: "script",
        data: { selected_type: selectedType, selected_entity: selectedEntity,
          selected_date: selectedDate, organization_id: organization_id, method_type: "Post", method_name: "compliance_calender", action_value: "compliance_calender", ticket_owner_id: ticket_owner_id},
          beforeSend: function(){
            $("#overlayDiv").show();
          },
          success: function (data) {
              GlobalClickFunction();
              setDueDates();
              $("#overlayDiv").hide();
              return true;
          },
          error: function(xhr,status,error){
            return false;
          }
      });
    }

    window.renderComplianceComponent = function(selectedType, selectedEntity,selectedDate) {
      var dashboard_key = $(".grc_dashboard_key").val();
      var url = '/grc/calendar/'+dashboard_key;
      RenderNextComponent({}, url);
    }


    window.LeadCreationPopup = function(service_id, action_type,complianceId, page_name, section_name){
      var vs_api_url = $('.environment_url').attr('value');
      var url = vs_api_url+"/api/v1/interested_service/"+service_id
      var header = {};
      header["key"] = $('#api_key').attr('value');
      header["token"] = $('.authentication_token').attr('value');
      var organization_id = $("#selected_organization_value").val();
      var payload = {}
      payload['action_type'] = action_type;
      payload['page_name'] = page_name;
      payload['section_name'] = section_name;
      payload['organization_id'] = organization_id;
      $.ajax({
          type: "post",
          dataType: "json",
          // url: url,
          // headers: {key: header["key"], token: header["token"] },
          // data: {action_type: action_type, organization_id: organization_id},
          url: '/get_grc_ajax_data',
          data: {payload: payload, url: url},
          success: function (data) {
              if (data['popup_identifier'] == "get_mobile_number_verified"){
                var content = "<h3>"+data['message']+"</h3><p>"+data['desc']+"</p>"
                var mobile_number = data['fields'][0]['value']
                $("#mobile_number_field_otp").val(mobile_number)
                $("#send_OTP").show();
                $("#send_OTP .content").html(content)
                var action = data['buttons']['action'][0]['action'];
                var service_id = data['service_id']
                if  (mobile_number == ''){
                  $("#send_otp_mobile_number_verification").attr('disabled', 'disabled')
                }
                var action_type = data['action_type']
                var page_name = data['page_name']
                var section_name = data['section_name']
                $("#send_otp_mobile_number_verification").attr('action', action)
                $("#send_otp_mobile_number_verification").attr('data-service-id', service_id)
                $("#send_otp_mobile_number_verification").attr('data-action_type', action_type)
                $("#send_otp_mobile_number_verification").attr('data-page_name', page_name)
                $("#send_otp_mobile_number_verification").attr('data-section_name', section_name)
                $("#send_otp_mobile_number_verification").attr('compliance_id', complianceId)
              }
              else{
                ShowLeadCreationPopup(data,complianceId)
              }
          },
          error: function(xhr,status,error){
            return false;
          }
      });

    }

    window.closeDuplicatePopup = function() {
      $("#service_already_present_popup").hide();
    }

    window.create_grc_second_lead = function(event) {
      $("#service_already_present_popup").hide();
      var vs_api_url = $('.environment_url').attr('value');
      var service_id_action = $('.create_grc_second_lead').val();
      var service_id = service_id_action.split("/")[0]
      var action_type = service_id_action.split("/")[1]
      var city_id = service_id_action.split("/")[2]
      var url = vs_api_url+"/api/v1"+ "/grc_lead_creation/"+ service_id
      var payload = {}
      payload["city_name"] = city_id;
      payload["action_type"] = event.dataset['action_type'];
      payload["page_name"] = event.dataset['page_name'];
      payload["section_name"] = event.dataset['section_name'];
      payload["allow_duplicate"] = true;
      // var header = {};
      // header["key"] = "e7e7a52397d57a5cd126328db7793009";
      // header["token"] = $('.authentication_token').attr('value');
      $.ajax({
          type: "post",
          dataType: "json",
          url: '/get_grc_ajax_data',
          data: {payload: payload, url: url},
          success: function (data) {
            // alert("hi");
            var action_type = data['action_type']
            var page_name = data['page_name']
            var section_name = data['section_name']
            $("#overlayDiv").hide();
            $("#service_already_present_popup").hide();
            if (data['message'] == 'Are you sure?' || data['message'] == 'You have a pending request'){
              $("#service_already_present_popup").show();
              $("#service_already_present_popup p").html(data['desc']);
              // GlobalClickFunction();
            }
            else if (data['action_type'] == 'Buy Now'){
              window.location.href = data['next_action']
            }
            else{
              $("#header_function li").removeClass('active');
              $("#header_function li a[href='#dashboard_interest']").parent().addClass('active');
              renderComponent('dashboard_interest')
              // var data = {};
              // data['filter_by'] = {};
              // var url = '/dashboard_page?step=dashboard_interest';
              // // $("#overlayDiv").show();
              // RenderNextComponent(data, url);
              // return false;
              // $("#lead_create_thanks_screen").show();
              // var service_name = data["data"]["ticket"]["subject"].split('Need Help with : ')[1]
              // var city_name = data["data"]["ticket"]["city_name"]
              // $("#lead_created_service_name").html(service_name);
              // $("#lead_created_city_name").append($("<option></option>").attr("value", city_name).text(city_name));
            }
          },
          error: function(xhr,status,error){
            return false;
          }
      });
    }

    window.ShowLeadCreationPopup = function(data,compliance_id){
      var action_type = data['action_type']
      var page_name = data['page_name']
      var section_name = data['section_name']
        // if (data['message'] == 'Are you sure?'){
        //   $("#service_already_present_popup").show();
        //   $("#service_already_present_popup p").html(data['desc']);
        //   $(".create_grc_second_lead").val(data["buttons"]["action"].split("/").pop() +"/"+ data["action_type"] +"/"+data['city_id']);
        //   // $(".action_type_val").val(data["action_type"]);
        // }
        if (action_type == "Buy Now"){
          // $("#buy_now_thanks_screen").show();
          var desc = data['desc'].split('Thanks for showing interest in our')[1]
          $("#buy_now_service_name_popup").html(desc)
          var action = data['buttons']["action"][0]['action']
          var selectValues = data['fields'][0]['options']
          var options_length = selectValues.length
          for (var i = 0; i <= (options_length - 1); i++) {
            if (data['fields'][0]["value"] == selectValues[i][1]){
              $('#buy_now_service_city_select').append($("<option selected='selected'></option>").attr("value", selectValues[i][1]).text(selectValues[i][0]));
            }
            else{
              $('#buy_now_service_city_select').append($("<option></option>").attr("value", selectValues[i][1]).text(selectValues[i][0]));
            }
          }
          var action = data['buttons']['action'][0]['action'];
          $("#proceed_to_buy_lead").removeAttr('disabled');
          $("#proceed_to_buy_lead").attr('action', action)
          $("#proceed_to_buy_lead").attr('data-action_type', action_type)
          $("#proceed_to_buy_lead").attr('data-page_name', page_name)
          $("#proceed_to_buy_lead").attr('data-section_name', section_name)
          $("#proceed_to_buy_lead").click();
        }
        else{
          // $("#thanks_screen").show();
          var desc = data['desc'].split('Thanks for showing interest in our')[1]
          $("#interested_service_name_popup").html(desc)
          var action = data['buttons']["action"][0]['action']
          var selectValues = data['fields'][0]['options']
          var options_length = selectValues.length
          for (var i = 0; i <= (options_length - 1); i++) {
            if (data['fields'][0]["value"] == selectValues[i][1]){
              $('#interested_service_city_select').append($("<option selected='selected'></option>").attr("value", selectValues[i][1]).text(selectValues[i][0]));
            }
            else{
              $('#interested_service_city_select').append($("<option></option>").attr("value", selectValues[i][1]).text(selectValues[i][0]));
            }
          }
          var action = data['buttons']['action'][0]['action'];
          $("#go_to_my_interests_lead").removeAttr('disabled');
          $("#go_to_my_interests_lead").attr('action', action)
          $("#go_to_my_interests_lead").attr('compliance_id', compliance_id)
          $("#go_to_my_interests_lead").attr('button_action_type', action_type)
          $("#go_to_my_interests_lead").data('data-action_type', action_type)
          $("#go_to_my_interests_lead").attr('data-page_name', page_name)
          $("#go_to_my_interests_lead").attr('data-section_name', section_name)
          $("#go_to_my_interests_lead").click();
        }
    }

    window.SendOTPLeadCreation = function(mobile_number, service_id, action_type, otp_type,compliance_id){
      var action = "/send_otp/"+service_id
      var vs_api_url = $('.environment_url').attr('value');
      var url = vs_api_url+"/api/v1"+ action
      var payload = {}
      payload["mobile_number"] = mobile_number;
      payload["action_type"] = action_type;
      var header = {};
      header["key"] = $('#api_key').attr('value');
      header["token"] = $('.authentication_token').attr('value');
      $.ajax({
          type: "post",
          dataType: "json",
          // url: url,
          // headers: {key: header["key"], token: header["token"] },
          // data: payload,
          url: '/get_grc_ajax_data',
          data: {payload: payload, url: url},
          success: function (data) {
            var action_type = data['action_type']
            $("#send_OTP").hide();
            $("#verify_mobile_otp").show();
            StartResendOtpTimer()
            var mobile_number = data['mobile_number']
            var service_id = data['service_id']
            $("#otp_send_mobile_number").text(mobile_number)
            var action = data['buttons']['action'][0]['action'];
            $("#verify_mobile_otp_submit_button").attr('action', action)
            $("#verify_mobile_otp_submit_button").attr('disabled', 'disabled')
            $("#verify_mobile_otp_submit_button").attr('data-action_type', action_type)
            $("#otp input").val("");
            $("#incorrect_otp_content").css("display", "none");
            $("#resend_otp_content").css("display", "none");
            if (otp_type == "resent_otp"){
              $("#mobile_resend_otp").css("display", "block");
            }
            else{
              $("#mobile_resend_otp").css("display", "none");
            }
           // console.log(service_id)
            $("#edit_mobile_number_send_otp").attr('data-service-id', service_id)
            $("#edit_mobile_number_send_otp").attr('compliance_id', compliance_id)
            // Resent OTP
            var otp_content = "resent_otp";
            $('#displayCounter').attr('onClick', 'SendOTPLeadCreation('+'"'+mobile_number+'"'+','+'"'+service_id+'"'+','+'"'+action_type+'"'+','+'"'+otp_content+'"'+')');
          },
          error: function(xhr,status,error){
            return false;
          }
      });
    }

    window.StartResendOtpTimer = function(){
      $(function() {
            var cnt = 59;
            var counter = setInterval(function() {
                if (cnt > 0) {
                    // $('#displayCounter').html(cnt);
                    var content = "Resend in "+cnt+"s"
                    $("#displayCounter span").html(content);
                    $("#displayCounter").css('cursor', 'none');
                    $('#displayCounter').attr('disabled', 'disabled')
                    cnt--;
                }
                else {
                    clearInterval(counter);
                    $('#displayCounter').removeAttr('disabled');
                    $("#displayCounter span").html("Resend");
                    $("#displayCounter").css('cursor', 'pointer');
                }
            }, 1000);
        });
    }

    window.ValidateInput = function() {
      if (($("#mobile_number_field_otp").parsley().validate() == true) && ($("#mobile_number_field_otp").val().length >= 10)){
        $("#send_otp_mobile_number_verification").removeAttr('disabled')
      }
      else{
        $("#send_otp_mobile_number_verification").attr('disabled', 'disabled')
      }

    }

    window.viewProfile = function(action){
      var payload = {};
      var dashboard_key = $(".grc_dashboard_key").val();
      var step_name = getSectionName("profile");
      var url = '/grc/'+step_name+'/'+dashboard_key;
      RenderNextComponent(payload, url);
      ProfilePage();
      return false;
    }

    window.getCommonPincode = function(){
      $('.common_pincode').select2({
        width: "100%!important",
        minimumInputLength: 3,
        ajax: {
          type: "get",
          dataType: "script",
          url: "/get_pincode_details",
          delay: 500,
          data: function(params){
            var ticket_id = "1142536";
            params.m = this
            return{
              pincode: params.term, ticket_id: ticket_id
            };
          },
          processResults: function(data, params) {
            if (data.length != 2){
              $("#pincode-notfound").modal('hide');
              var myResults;
              myResults = void 0;
              myResults = []
              $.each(JSON.parse(data) , function(index, val) {
                myResults.push({
                  id: val.id,
                  text: val.name
                });
              });
              return {
                results: myResults
              };
            }
            else{
              var user_type = $("#current_user_type").val();
              if (user_type != "agent"){
                $("#pincode-notfound").modal('show');
                var $select_district = params.m.parent().parent().find('.common_city');
                var $select_country = params.m.parent().parent().find('.common_country');
                var $select_state = params.m.parent().parent().find('.common_state');
                $select_district.val("");
                $select_country.val("");
                $select_state.val("");
              }
              else{
                $("#overlayDiv").hide();
                var pincode_value  = $(this).closest('form').find('.common_pincode').val();
                var city_value = params.m.parent().parent().find('.common_city').val();
                var district_value = params.m.parent().parent().find('.common_district').val();
                var country_value = params.m.parent().parent().find('.common_country').val();
                var state_value = params.m.parent().parent().find('.common_state').val();
                $("#dsc-pincode-edit .gst_edit_pincode").val(pincode_value);
                $("#dsc-pincode-edit .gst_edit_district").val(district_value);
                $("#dsc-pincode-edit .gst_edit_city").val(city_value);
                $("#dsc-pincode-edit .gst_edit_state").val(state_value);
                $("#dsc-pincode-edit .gst_edit_country").val(country_value);
                var id  = params.m.parent().parent().find('.dsc_conversation_id').val();
                $("#dsc-pincode-edit .conversation_id").val(id);
                $("#dsc-pincode-edit").modal('show');
              }
            }
          }
        }
      });

      $('.common_pincode').on('select2:select', function (e) {
       // console.log(e.params.data);
        var data = e.params.data;
        var $t = $(this);
        var pincode_details = data["text"].split("-")[1].split(", ");
       // console.log($t.parent().parent().parent());
        var $select_city = $t.parent().parent().parent().parent().parent().find('.common_city');
        var $select_country = $t.parent().parent().parent().parent().parent().find('.common_country');
        var $select_state = $t.parent().parent().parent().parent().parent().find('.common_state');
        var $select_district = $t.parent().parent().parent().parent().parent().find('.common_district');
        $select_state.val(pincode_details[2]);
        $select_district.val(pincode_details[1]);
        $select_city.val(pincode_details[0]);
        $select_country.val("India");
      });
    }
  }

  window.SettingsFunction = function(){
    $("#settings_left_section li a").click(function() {
      var id = $(this).attr('href').split('#')[1]
      var payload = {};
      var dashboard_key = $(".grc_dashboard_key").val();
      var step_name = getSectionName(id);
      var url = '/grc/'+step_name+'/'+dashboard_key;
      RenderNextComponent(payload, url);
    });
  }


  window.BusinessProfile = function(){
    SearchCompanyName();
    BusinessType();
    logoUpload();
    changeBusinessType();
    IdentifierType();
    closePopup();
  }

  window.PersonalDetailFunction = function(){
    $("#profile_left_section li a").click(function() {
      var id = $(this).attr('href').split('#')[1];
      var payload = {};
      var dashboard_key = $(".grc_dashboard_key").val();
      var step_name = getSectionName(id);
      var url = '/grc/'+step_name+'/'+dashboard_key;
      RenderNextComponent(payload, url);
      if (step_name == "personal-info"){
        ProfilePage();
      }
      return false;
    });
  }

  window.BackButton = function(){
    $('.dashboard_page_back_button').click(function(){
      $("#header_function li").removeClass('active');
      $("#header_function li a[href='#dashboard_home']").parent().addClass('active');
      var payload = {};
      var dashboard_key = $(".grc_dashboard_key").val();
      var step_name = getSectionName("dashboard_home");
      var url = '/grc/'+step_name+'/'+dashboard_key;
      RenderNextComponent(payload, url);
    });

    $('.dashboard_messages_back_button').click(function(){
      var url = window.location.pathname.replace('show_message', 'messages');
      RenderComponentMessage({}, url);
    });
  }

// function BuildDocumentsContent(documents_content){
  window.BuildDocumentsContent = function(documents_content){
    var content = "<h5>Documents</h5><p class='grc_para_desc'>"+documents_content['data'][0]['description']+"</p>"
    return content;
  }

  // function BuildBenefitsContent(benefit_content){
  window.BuildBenefitsContent = function(benefit_content){
    var content = " <h5>Benefits</h5><p class='grc_para_desc'>"+benefit_content['data'][0]['description']+"</p>"
    return content;
  }

  // function BuildSummaryContent(summary_content){
  window.BuildSummaryContent = function(summary_content){
    var content = "<h5>Summary</h5><p class='grc_para_desc'>"+summary_content['data'][0]['description']+"</p>"
    return content;
  }

  // function BuildOverviewContent(overview_content){
  window.BuildOverviewContent = function(overview_content){
    for (var i = 0; i <= (overview_content['data'].length - 1); i++) {
        var title = overview_content['data'][i]['title'];
        switch (title) {
          case "Description":
            var description_data  = overview_content['data'][i]['description'];
            if (description_data == null){
              description_data = "";
            }
            break;
          case "Duration":
            var duration_data  = overview_content['data'][i]['description'];
            if (duration_data == null){
              duration_data = "";
            }
            break;
          case "Deliverables":
            var deliverables_data  = overview_content['data'][i]['description'];
            break;
          case "Penalty":
            var penalty_data  = overview_content['data'][i]['description'];
            if (penalty_data == null){
              penalty_data = "";
            }
            break;
          default:
            break;
        }
      }
   var content = "<h5>Description</h5><p class='popup_new_para'>"+description_data+"</p>  <div class='row'><div class='col-md-6'><h5>Duration</h5><p class='popup_new_para'>"+duration_data+"</p></div><div class='col-md-6'><h5>Penalty</h5><p class='popup_new_para'>"+penalty_data+"</p></div></div>"
   return content
  }

  function BuildBenefitContent(benefit_content){
    var content = "<h5>"+benefit_content["data"][0]["title"]+"</h5><p class='grc_para_desc'>"+benefit_content["data"][0]["description"]+"</p>"
    return content
  }

  function BuildDocumentContent(document_content){
    var content = "<h5>"+document_content["data"][0]["title"]+"</h5><p class='grc_para_desc'>"+document_content["data"][0]["description"]+"</p>"
    return content
  }
  window.BuildDeliverableContent = function(deliverable_content){
    if (deliverable_content != undefined)
    {
      var content = "<h5>"+deliverable_content["data"][0]["title"]+"</h5><p class='grc_para_desc'>"+deliverable_content["data"][0]["description"]+"</p>"
      return content
    }
  }

window.DashboardClickFunction = function(){
  DashboardPage();
  SettingsFunction();
  PersonalDetailFunction();
  ProfilePage();
  BackButton();
  $('#dashboard_left_section_home li a').click(function(e){
    e.preventDefault();
    var dashboard_key = $(".grc_dashboard_key").val();
    var step = $(this).attr('href').split('#')[1]
    var payload = {};
    var step_name = getSectionName(step);
    var url = '/grc/'+step_name+'/'+dashboard_key;
    RenderNextComponent(payload, url);
    return false;
  });

  $('#dashboard_left_section_home_new div a').click(function(e){
    e.preventDefault();
    var dashboard_key = $(".grc_dashboard_key").val();
    var step = $(this).attr('href').split('#')[1]
    var payload = {};
    var step_name = getSectionName(step);
    var url = '/grc/'+step_name+'/'+dashboard_key;
    RenderNextComponent(payload, url);
    return false;
  });
  $('.interested_service').unbind().click(function(e){
    e.preventDefault();
    $("#overview_service").hide();
    $("#compliance_calender_know_more").hide();
    $(".sending_otp_button").hide();
    $('#send_otp_mobile_number_verification').show();
    var service_id = $(this).attr('value')
    var action_type = $(this).data('actionName')
    var page_name = $(this).data('pageName')
    var section_name = $(this).data('sectionName')
    var compliance_id = $(this).data('compliance_id')
    LeadCreationPopup(service_id, action_type, compliance_id, page_name, section_name)
  });

  $("#edit_mobile_number_send_otp").click(function(){
    $("#send_OTP").show();
    $("#verify_mobile_otp").hide();
    $(".sending_otp_button").hide();
    $("#send_otp_mobile_number_verification").show();
    var service_id = $(this).attr('data-service-id')
    var action_type = $(this).data('actionName')
    var compliance_id = $(this).data('compliance_id')
    var page_name = $(this).data('pageName')
    var section_name = $(this).data('sectionName')
    LeadCreationPopup(service_id, action_type, compliance_id, page_name, section_name)
  });

  $("#go_to_my_interests_lead, #proceed_to_buy_lead").unbind().click(function(e){
    e.preventDefault();
    $(this).attr('disabled', 'disabled');
    if ($(this).data('action_type') == 'Buy Now'){
      var action_type = 'Buy Now'
      var city_id = $("#buy_now_service_city_select").val();
    }
    else{
      var action_type = $(this).attr('button_action_type') ? $(this).attr('button_action_type') : "I'm Interested"
      var city_id = $("#interested_service_city_select").val();
      var service_name = $('#interested_service_name_popup b').text();
      var compliance_id = $(this).attr('compliance_id') ? $(this).attr('compliance_id') : ''
    }
    var fibouseremail = $('.fibouseremail').val();
    var customerid = $('.fibocustomerid').val();
    var fibousername = $('.fibousername').val();
    var role = $('.fibouserrole').val();
    var entityid = $('.fiboentityid').val();
    var entityname = $('.fiboentityname').val();
    var entitytype = $('.fiboentitytype').val();
    var action = $(this).attr('action');
    var organization_id = $('#selected_organization_value').val();
    var vs_api_url = $('.environment_url').attr('value');
    var url = vs_api_url+"/api/v1"+ action
    var section_name = $(this).data('section_name')
    var page_name = $(this).data('page_name')
    var action_name = $(this).data('action_type')
    var payload = {}
    payload["city_name"] = city_id;
    payload["action_type"] = action_type;
    payload["organization_id"] = organization_id;
    payload["page_name"] = page_name;
    payload["section_name"] = section_name;
    payload["action_name"] = action_name;

    if (compliance_id){
      payload["compliance_id"] = compliance_id
    }
    var header = {};
    header["key"] = $('#api_key').attr('value');
    header["token"] = $('.authentication_token').attr('value');
    // I'm Interested
    $.ajax({
        type: "post",
        dataType: "json",
        url: '/get_grc_ajax_data',
        // headers: {key: header["key"], token: header["token"] },
        data: {payload: payload, url: url},
        success: function (data) {
          action_type = data['action_type'];
          page_name = data['page_name'];
          section_name = data['section_name'];
          $("#buy_now_thanks_screen").hide();
          $("#thanks_screen").hide();
          var pageDataForMixPanel = getGrcSourceAndCurrentPage();

          vsTrackEvent({
            eventName: action_type === 'Buy Now' ? 'buy_now_clicked':'i_am_interested_clicked',
            params: Object.assign(pageDataForMixPanel, {
              action_type,
              role,
              service_name,
              email: fibouseremail,
              name: fibousername,
              entity_id: entityid,
              entity_name: entityname,
              entity_type: entitytype
            })
          });

          if (data['message'] == 'Are you sure?' || data['message'] == 'You have a pending request'){
            $(".create_grc_second_lead").val(data["buttons"]["action"].split("/").pop() +"/"+ data["action_type"] +"/"+data['city_id']);
            // $(".action_type_val").val(data["action_type"]);
            $("#service_already_present_popup").show();
            $("#service_already_present_popup p").html(data['desc']);
            $(".create_grc_second_lead").attr('data-action_type', action_type)
            $(".create_grc_second_lead").attr('data-page_name', page_name)
            $(".create_grc_second_lead").attr('data-section_name', section_name)
            // GlobalClickFunction();
          }
          else if (data['action_type'] == 'Buy Now'){
            vsTrackEvent({
              eventName: "lead_success",
              params: Object.assign(pageDataForMixPanel, {
                action_type,
                role,
                service_name,
                email: fibouseremail,
                name: fibousername,
                entity_id: entityid,
                entity_name: entityname,
                entity_type: entitytype
              })
            });
            window.location.href = data['next_action'];
            // var service_name = data["data"]["ticket"]["subject"].split('Need Help with : ')[1]
            // var city_name = data["data"]["ticket"]["city_name"]
          }
          else{
            var render_data = {};
            render_data['filter_by'] = {};
            var step_name = getSectionName("dashboard_interest");
            var dashboard_key = $(".grc_dashboard_key").val();
            var url = '/grc/'+step_name+'/'+dashboard_key;
            RenderNextComponent(render_data, url);
            vsTrackEvent(
              {
                eventName: "lead_success",
                params: Object.assign(pageDataForMixPanel, {
                  role,
                  action_type,
                  service_name,
                  email: fibouseremail,
                  name: fibousername,
                  entity_id: entityid,
                  entity_name: entityname,
                  entity_type: entitytype
                })
              }
            );

            return false;
          }
        },
        error: function(xhr,status,error){
          return false;
        }
    });
  });

  $('.know_more').click(function(){
    var service_id = $(this).attr('value')
    var know_more_from = $(this).attr('id')
    var vs_api_url = $('.environment_url').attr('value');
    var url = vs_api_url+"/api/v1/service/"+ service_id
    var header = {};
    var payload = {}
    payload["know_more_from"] = know_more_from;
    header["key"] = $('#api_key').attr('value');
    header["token"] = $('.authentication_token').attr('value');
     $.ajax({
        type: "post",
        dataType: "json",
        url: url,
        url: '/get_grc_ajax_data',
        // headers: {key: header["key"], token: header["token"] },
        data: {payload: payload, url: url},
        success: function (data) {
          $("#overview_service").show();
          $(".grc_btns_group").find("[data-id='tab1']").addClass('active');
          var service_name = data['title']
          var service_id = data['service_id']
          var amount = data['amount']
          var overview_length = data['data'].length
          var ptl_tag = data['ptl_tag']
          $('.overview_service_name').html(service_name)
          if ((amount != undefined) && (amount != '')){
            $("#overview_service_amount").html(amount)
          }
          else{
            $("#overview_service_amount").html("")
          }
          $(".overview_service_id").attr('value', service_id)
          if ((ptl_tag != undefined) && (ptl_tag != '')){
            $(".service_ptl_tag").html("<p class='gst_sub_para'>"+ptl_tag+"</p>")
          }
          else{
            $(".service_ptl_tag").html("")
          }
          for (var i = 0; i <= (overview_length - 1); i++) {
            var title = data['data'][i]['title'];
            switch (title) {
              case 'Summary':
                var summary_detail = data['data'][i]
                break;
              case 'Overview':
                var overview_detail = data['data'][i]
                break;
              case 'Benefits':
                var benefit_detail = data['data'][i]
                break;
              case 'Documents':
                var document_detail = data['data'][i]
                break;
              case 'Deliverables':
                var deliverable_detail = data['data'][i]
                break;
              default:
                break;
            }
          }
          var overview_content = BuildOverviewContent(overview_detail)
          var benefit_content = BuildBenefitsContent(benefit_detail)
          var summary_content = BuildDeliverableContent(deliverable_detail)
          var documents_content = BuildDocumentsContent(document_detail)
          $("#overview_content").html(overview_content)
          $("#benefits_content").html(benefit_content)
          $("#deliverables_content").html(summary_content)
          $("#documents_content").html(documents_content)
          if ((know_more_from != undefined) && (know_more_from != '') && (know_more_from == "my_interest")){
            $("#know-more-button").hide();
            $("#inclusive").hide();
          }else{
            if (data["buttons"]["action"][0]["text"] == "Request for Quote"){
              $("#inclusive").hide();
              $("#quote-know-more-button").show();
              $("#know-more-button").hide();
            }
            else{
              $("#quote-know-more-button").hide();
              $("#know-more-button").show();
              $("#inclusive").show();
            }
          }
          if (data['is_interested_button_enable'] == false){
            $('.iminterested-disable').addClass('interested-button-disable');
          }
          else{
            $('.iminterested-disable').removeClass('interested-button-disable');
          }
          // var benefit_content = BuildBenefitContent(benefit_detail)
          // $("#benefits_content").html(benefit_content)
          // var document_content = BuildDocumentContent(document_detail)
          // $("#documents_content").html(document_content)
          // var deliverable_content = BuildDeliverableContent(deliverable_detail)
          // $("#deliverables_content").html(deliverable_content)
        },
        error: function(xhr,status,error){
          return false;
        }
    });
  });

  $('.view-quotation').click(function(){
    var quotation_id = $(this).attr('quotation_id')
    var ticket_id = $(this).attr('ticket_id')
    var vs_api_url = $('.environment_url').attr('value');
    var url = vs_api_url+"/api/v1/grc/generate_quotation"
    var header = {};
    var payload = {}
    payload["quotation_id"] = quotation_id;
    payload["ticket_id"] = ticket_id;
    header["key"] = $('#api_key').attr('value');
    header["token"] = $('.authentication_token').attr('value');
    $.ajax({
      type: "post",
      dataType: "json",
      // url: url,
      // headers: {key: header["key"], token: header["token"] },
      // data: payload,
      url: '/get_grc_ajax_data',
      data: {payload: payload, url: url},
      success: function (data) {
        var url = data['service_url']
        var error = data['error']
        if ((url != undefined) && (url != "")){
          window.open(url, "_blank");
        }
        else {
          alert(error);
        }
        return false;
      }
    });
  });

  $('.buy_now').click(function(){
    var ticket_id = $(this).attr('ticket_id')
    var payment_url = $(this).attr('payment_url')
    var page_name = $(this).attr('page_name')
    var vs_api_url = $('.environment_url').attr('value');
    var url = vs_api_url+"/api/v1/grc/payment_log_creation"
    var header = {};
    var payload = {}
    payload["action_type"] = "Buy Now";
    payload["ticket_id"] = ticket_id;
    payload["page_name"] = page_name;
    header["key"] = $('#api_key').attr('value');
    header["token"] = $('.authentication_token').attr('value');
    $.ajax({
      type: "post",
      dataType: "json",
      // url: url,
      // headers: {key: header["key"], token: header["token"] },
      // data: payload,
      url: '/get_grc_ajax_data',
      data: {payload: payload, url: url},
      success: function (data) {
        var success = data['success']
        var error = data['error']
        if ((success != undefined) && (success != "")){
          window.open(payment_url, "_blank");
        }
        else {
          alert(error);
        }
        return false;
      }
    });
  });

  $('.request_quote').click(function(){
    var ticket_id = $(this).attr('ticket_id')
    var vs_api_url = $('.environment_url').attr('value');
    var url = vs_api_url+"/api/v1/ticket/" +ticket_id+"/request_quote"
    var header = {};
    var payload = {}
    payload["ticket_id"] = ticket_id;
    header["key"] = $('#api_key').attr('value');
    header["token"] = $('.authentication_token').attr('value');
    $.ajax({
      type: "get",
      dataType: "json",
      // url: url,
      // headers: {key: header["key"], token: header["token"] },
      // data: payload,
      url: '/get_grc_ajax_data',
      data: {payload: payload, url: url, method_type: "get"},
      success: function (data) {
       // console.log(data);
        var title = data['title']
        var message = data['message']
        if ((title != undefined) && (title != "")){
          $(".green-banner").show();
        }
        return false;
      }
    });
  });

  $('#send_otp_mobile_number_verification').click(function(){
    $(this).hide();
    $(".sending_otp_button").show();
    var service_id = $(this).attr('data-service-id');
    var compliance_id = $(this).attr('compliance_id');
    var mobile_number = $("#mobile_number_field_otp").val()
    var action_type = $(this).data('action_type')
    SendOTPLeadCreation(mobile_number, service_id, action_type, "first_otp",compliance_id)
  });

  $('#verify_mobile_otp_submit_button').click(function(){
    $(this).hide();
    var fibouseremail = $('.fibouseremail').val();
    var customerid = $('.fibocustomerid').val();
    var fibousername = $('.fibousername').val();
    var role = $('.fibouserrole').val();
    var entityid = $('.fiboentityid').val();
    var entityname = $('.fiboentityname').val();
    var entitytype = $('.fiboentitytype').val();
    var service_name = $('.overview_service_name').text();
    var service_id = $('.overview_service_id').text();
    vsTrackEvent({
      eventName: "View_mobile_verification",
      params: {
        service_id,
        service_name,
        role,
        email: fibouseremail,
        customer_id: customerid,
        name: fibousername,
        entity_id: entityid,
        entity_name: entityname,
        entity_type: entitytype
      }
    });
    var action_type = $(this).data('action_type')
    $(".verifying_otp_button").show();
    var length = $("#otp input").length
    var otp_value = ""
    for (var i = 0; i <= (length - 1); i++) {
        otp_value = otp_value + $("#otp input")[i].value
    }
    var mobile_number = $("#mobile_number_field_otp").val()
    var action = $(this).attr('action');
    var vs_api_url = $('.environment_url').attr('value');
    var url = vs_api_url+"/api/v1"+ action
    var payload = {}
    payload["mobile_number"] = mobile_number;
    payload["otp"] = otp_value;
    payload["action_type"] = action_type;
    var header = {};
    header["key"] = $('#api_key').attr('value');
    header["token"] = $('.authentication_token').attr('value');
    $.ajax({
        type: "post",
        dataType: "json",
        // url: url,
        // headers: {key: header["key"], token: header["token"] },
        // data: payload,
        url: '/get_grc_ajax_data',
        // headers: {key: header["key"], token: header["token"] },
        data: {payload: payload, url: url},
        success: function (data) {
          $("#verify_mobile_otp").hide();
          $("#mobile_verify").show();
          vsTrackEvent({
            eventName: "Mobile_verification_success",
            params: {
              role,
              service_id,
              service_name,
              mobile:mobile_number,
              email:fibouseremail,
              customer_id: customerid,
              name: fibousername,
              entity_id: entityid,
              entity_name: entityname,
              entity_type: entitytype}
          });
          // $("#thanks_screen").show();
          // var desc = data['desc'].split('Thanks for showing interest in our')[1]
          // $("#interested_service_name_popup").html(desc)
          // var action = data['buttons']["action"][0]['action']
          // var selectValues = data['fields'][0]['options']
          // var options_length = selectValues.length
          // for (var i = 0; i <= (options_length - 1); i++) {
          //   $('#interested_service_city_select').append($("<option></option>").attr("value", selectValues[i][1]).text(selectValues[i][0]));
          // }
          // var action = data['buttons']['action'][0]['action'];
          // $("#go_to_my_interests_lead").attr('action', action)
          setTimeout( function() {
            ShowLeadCreationPopup(data);
            $("#mobile_verify").hide();
          }, 3000 );
          // ShowLeadCreationPopup(data)
        },
        error: function(xhr,status,error){
          $(".verifying_otp_button").hide();
          $('#verify_mobile_otp_submit_button').show();
          $("#incorrect_otp_content").css("display", "block");
          return false;
        }
    });
  });

  $("#otp input").keyup(function (){
    var length = $("#otp input").length
    var invalid_otp_value = false
    for (var i = 0; i <= (length - 1); i++) {
      if ($("#otp input")[i].value == ""){
        invalid_otp_value = true;
      }
    }
    if (invalid_otp_value == true){
      $("#verify_mobile_otp_submit_button").attr('disabled', 'disabled')
    }
    else{
      $("#verify_mobile_otp_submit_button").removeAttr('disabled')
    }
  });

  window.ServicePage = function(){
    $("#dashboard_service_filter").select2({
      minimumResultsForSearch: -1,
      placeholder: 'Please enter 3 or more characters',
      minimumInputLength: 3,
      allowClear: true
    });

    $('#dashboard_service_filter').on('select2:select', function (e) {
      var fibouseremail = $('.fibouseremail').val();
      var customerid = $('.fibocustomerid').val();
      var fibousername = $('.fibousername').val();
      var role = $('.fibouserrole').val();
      var entityid = $('.fiboentityid').val();
      var entityname = $('.fiboentityname').val();
      var entitytype = $('.fiboentitytype').val();
      var service_name = $(".select2-selection__rendered").attr('title');
      var service_id = $(this).find(":selected").val();
      var search_query = $('.select2-search__field').val();
      vsTrackEvent({
        eventName: "Top_Searchbar",
        params: {
          role,
          search_query,
          service_name,
          email: fibouseremail,
          serviceId: service_id,
          customer_id: customerid,
          name: fibousername,
          entity_id: entityid,
          entity_name: entityname,
          entity_type: entitytype
        }
      });
      var vs_api_url = $('.environment_url').attr('value');
      var url = vs_api_url+"/api/v1/service/"+ service_id
      var header = {};
      header["key"] = $('#api_key').attr('value');
      header["token"] = $('.authentication_token').attr('value');
       $.ajax({
          type: "post",
          dataType: "json",
          // url: url,
          // headers: {key: header["key"], token: header["token"] },
          // data: {},
          url: '/get_grc_ajax_data',
          // headers: {key: header["key"], token: header["token"] },
          data: {payload: {}, url: url},
          success: function (data) {
            $("#overview_service").show();
            $(".grc_btns_group").find("[data-id='tab1']").addClass('active');
            var service_name = data['title']
            var service_id = data['service_id']
            var amount = data['amount']
            var overview_length = data['data'].length
            var ptl_tag = data['ptl_tag']
            $('.overview_service_name').html(service_name)
            $("#overview_service_amount").html(amount)
            $(".overview_service_id").attr('value', service_id)
            if ((ptl_tag != undefined) && (ptl_tag != '')){
              $(".service_ptl_tag").html("<p class='gst_sub_para'>"+ptl_tag+"</p>")
            }
            else{
              $(".service_ptl_tag").html("")
            }
            for (var i = 0; i <= (overview_length - 1); i++) {
              var title = data['data'][i]['title'];
              switch (title) {
                case 'Summary':
                  var summary_detail = data['data'][i]
                  break;
                case 'Overview':
                  var overview_detail = data['data'][i]
                  break;
                case 'Benefits':
                  var benefit_detail = data['data'][i]
                  break;
                case 'Documents':
                  var document_detail = data['data'][i]
                  break;
                case 'Deliverables':
                  var deliverable_detail = data['data'][i]
                  break;
                default:
                  break;
              }
            }
            // var overview_content = BuildOverviewContent(overview_detail)
            // var benefit_content = BuildBenefitsContent(benefit_detail)
            // var summary_content = BuildSummaryContent(summary_detail)
            // var documents_content = BuildDocumentsContent(document_detail)
            // $("#overview_content").html(overview_content)
            // $("#benefits_content").html(benefit_content)
            // $("#summary_content").html(summary_content)
            // $("#documents_content").html(documents_content)
            var overview_content = BuildOverviewContent(overview_detail)
            var benefit_content = BuildBenefitsContent(benefit_detail)
            var summary_content = BuildDeliverableContent(deliverable_detail)
            var documents_content = BuildDocumentsContent(document_detail)
            $("#overview_content").html(overview_content)
            $("#benefits_content").html(benefit_content)
            $("#deliverables_content").html(summary_content)
            $("#documents_content").html(documents_content)
            if (data["buttons"]["action"][0]["text"] == "Request for Quote"){
              $("#inclusive").hide();
              $("#quote-know-more-button").show();
              $("#know-more-button").hide();
            }
            else{
              $("#quote-know-more-button").hide();
              $("#know-more-button").show();
              $("#inclusive").show();
            }
            if (data['is_interested_button_enable'] == false){
              $('.iminterested-disable').addClass('interested-button-disable');
            }
            else{
              $('.iminterested-disable').removeClass('interested-button-disable');
            }
          },
          error: function(xhr,status,error){
            return false;
          }
      });
    });
  }
  ServicePage();

  window.SubmitAction = function(){
    $('.submit_button').click(function(){
      var url = $(this).attr('action')
      var success = false;
      var mandatory_values = [];
      if (($(this).text().trim() == 'Continue') || ($(this).text().trim() == 'Save')){
        var mandatory_fields = GetMandatoryFields($(this));
        if (mandatory_fields.length > 0){
          for(var i=0; i<=(mandatory_fields.length - 1); i++){
            if ($("."+mandatory_fields[i]).parsley().validate() == true){
              mandatory_values.push(true);
            }
            else{
              mandatory_values.push(false);
            }
          }
          if (mandatory_values.includes(false)){
            success = false;
          }
          else{
            success = true;
          }
        }
        else{
          success = true;
        }
      }
      else{
        success = true
      }
      if (success == true)
      {
        // var url = 'http://localhost:3001/api/v1'+url
        var vs_api_url = $('.environment_url').attr('value');
        var url = vs_api_url+'/api/v1'+url
        var payload = {};
        // var id = $('#organization_id').attr('value');
        payload['attributes'] = {};
        // payload['table_name'] = $('#table_name').attr('value');
        // payload['attributes']['id'] = id;
        if (($(this).attr('value') == 'continue') || ($(this).attr('value') == 'save')){
          if (($("#tab_identifier").attr('value') == 'business-profile') || ($("#tab_identifier").attr('value') == 'profile_business')){
            if ($(this).attr('action') == '/grc/business_details'){
              payload['table_name'] = 'business_details'
            }
            else{
              payload['table_name'] = 'business_address'
            }
          }
          payload = BuildPayload(payload)
        }
        var header = {};
        header["key"] = $('#api_key').attr('value');
        header["token"] = $('.authentication_token').attr('value');
        SubmitData(payload, url, header);
        if (($("#tab_identifier").attr('value') == 'business-profile') || ($("#tab_identifier").attr('value') == 'profile_business')){
          window.location.reload();
        }
        return false;
      }
      else{
        return false;
      }
    });

    $('.cancel_button').click(function(){
      var id = $(this).attr('data-page-name')
      var dashboard_key = $(".grc_dashboard_key").val();
      var payload = {};
      var step_name = getSectionName(id);
      var url = '/grc/'+step_name+'/'+dashboard_key;
      RenderNextComponent(payload, url);
    });
  }
  SubmitAction();

  window.GetMandatoryFields = function(ele){
    // var data = $('.form-control')
    var data = ele.closest('form').find('.form-control')
    var fields_ids_to_validate =[]
    for(var i=0; i<=(data.length - 1); i++){
      if (!(data[i].attributes['data-parsley-required'] == undefined))
      {
        if (data[i].attributes['data-parsley-required'].value == 'true'){
          fields_ids_to_validate.push(data[i].attributes['name'].value)
        }
      }
    }
  return fields_ids_to_validate;
  }

  window.SubmitData = function(payload, url, header){
   // console.log("URL"+url)
   // console.log("PAYLOAD"+payload)
    $.ajax({
      type: "post",
      dataType: "json",
      // url: url,
      // headers: {key: header["key"], token: header["token"] },
      // data: payload,
      url: '/get_grc_ajax_data',
      data: {payload: payload, url: url},
      success: function (data) {
        var url = '';
        if (data['content'] == 'KYC'){
          $(".green-banner").show();
        }
        else if (data['content'] == 'My address'){
          $(".green-banner").show();
        }
        else if (data['content'] == 'Business location'){
          $(".green-banner").show();
        }
        else if(data['content'] == 'Business details'){
          $(".green-banner").show();
        }
        else if(data['json']['message'] == 'Email Sent Successfully'){
          var dashboard_key = $(".grc_dashboard_key").val();
          var ticket_id = data['json']['ticket_id']
          url = '/grc/messages/'+dashboard_key;
          var data = {};
          data['ticket_id'] = ticket_id
          RenderNextComponentMessages(data, url)
        }
        else{
          $(".green-banner").show();
        }
    SubmitAction();
      },
      error: function(xhr,status,error){
      }
    });
  }

  window.SearchCompanyName = function(){
    var business_types = ["pvt","opc","section_8", "llp","foreign","nbfc","nidhi","producer"];
    var current_business_type = $('.business_type').val();
    var header = {};
    header["key"] = $('#api_key').attr('value');
    header["token"] = $('.authentication_token').attr('value');
      var vs_api_url = $('.environment_url').attr('value');
    if (business_types.includes(current_business_type)){
      $(".identification_number").attr('readonly', true);
      $('.business_name').select2({
        placeholder: 'Please enter 3 or more characters',
        minimumInputLength: 3,
        ajax: {
          type: "POST",
          dataType: "json",
          url: '/get_grc_ajax_data',
        // headers: {key: header["key"], token: header["token"] },
          delay: 500,
          data: function(params){
            var keyword = params.term;
            var payload = {}
            payload['attributes'] = {};
            payload['attributes']['name'] = keyword;
            payload['attributes']['business_type'] = current_business_type;
            return {payload: payload, url: vs_api_url+"/api/v1/grc/company_name_lookup"}
          },
          processResults: function(data, params) {
            Select2ClickeEvent()
            SubmitAction();
            ButtonEnable();
            var length = data.length
            var myResults;
            myResults = void 0;
            myResults = []
            $.each(data , function(index, val) {
              myResults.push({
                id: val['cin_no'],
                text: val['company_name']
            });
            });
            return {
              results: myResults
            };
          }
        }
      });
    }
    else{
      SubmitAction();
    }
  }
  SearchCompanyName();

  window.BusinessType = function(){
    $('#business_profile_form .business_type').change(function(){
      var value = $(this).val();
      $("#confirm_business_type").css("display", "block");
      $("#confirm_business_type .change_business_type").val(value);
      changeBusinessType();
      closePopup();
    });
  }
  BusinessType();

  window.changeBusinessType = function(){
    $(".change_business_type").click(function(){
      var payload = {};
      payload['confirm'] = 'true'
      payload['attributes'] = {};
      payload['attributes']['business_type'] = $(this).val();
      payload['table_name'] = "business_details";
      var id = $("#selected_organization_value").val();
      payload['id'] = id;
      var vs_api_url = $('.environment_url').attr('value');
      var url = vs_api_url+'/api/v1/grc/business_details';
      var header = {};
      header["key"] = $('#api_key').attr('value');
      header["token"] = $('.authentication_token').attr('value');
      SubmitData(payload, url, header);
      var id = "profile_business";
      var step_name = getSectionName(id);
      var dashboard_key = $(".grc_dashboard_key").val();
      $.ajax({
        type: "post",
        dataType: "script",
        url: window.location.origin+ '/grc/'+step_name+'/'+dashboard_key,
        data: {payload: payload},
        success: function (data) {
          BusinessProfile();
          IdentifierType();
          ButtonEnable();
          GlobalClickFunction();
          return false;
        }
      });
    });
  }
  changeBusinessType();
  window.closePopup = function(){
    $(".cancel_business_type").click(function(){
      $("#confirm_business_type").css("display", "none");
      var id = "profile_business";
      var payload = {};
      var step_name = getSectionName(id);
      var url = '/grc/'+step_name+'/'+dashboard_key;
      RenderNextComponent(payload, url);
    });
  }
  closePopup();

  window.Select2ClickeEvent = function(){
    $('.business_name').on('select2:select', function (e) {
      var text = $(this).find(":selected").text();
      var value = $(this).find(":selected").val();
     // console.log("Company Name :"+text)
     // console.log("CIN :"+value)
      if (value == 'NIL'){
        //  var value = $(this).val();
        //   var payload = {};
        //   payload['id'] = $('#selected_organization_value').val();
        //   payload['attributes'] = {};
        //   payload['attributes']['is_company_name_editable'] = true;
    //   payload['attributes']['business_name'] = text;
    //   var header = {};
      //   header["key"] = "e7e7a52397d57a5cd126328db7793009";
      //   header["token"] = $('.authentication_token').attr('value');
        //   var vs_api_url = $('.environment_url').attr('value');
        //   $.ajax({
        //         type: "post",
        //         dataType: "json",
    //    url: vs_api_url+"/api/v1/grc/business_details",
    //    headers: {key: header["key"], token: header["token"] },
        //         data: payload,
        //         success: function (data) {
        //        BusinessType();
        //        SubmitAction();
        //          return true;
        //        },
        //       error: function(xhr,status,error){
        //         return false;
        //       }
        //     });
        // $('.identification_number').removeAttr('readonly')
    var id = "profile_business";
    var close_popup = '';
    var payload = {};
    payload['id'] = $("#selected_organization_value").val();
    payload['attributes'] = {};
        payload['attributes']['is_company_name_editable'] = "true";
    payload['attributes']['business_name'] = text;
    $.ajax({
      type: "post",
      dataType: "script",
      url: '/dashboard_page?step='+id+'&close_popup='+close_popup,
      data: {payload: payload},
      success: function (data) {
      BusinessProfile();
      SubmitAction();
      $('.identification_number').removeAttr('readonly');
      $("#business_profile_form").find('.cancel_button').removeAttr('disabled');
      $("#business_profile_form").find('.submit_button').removeAttr('disabled');
      return true;
      },
      error: function(xhr,status,error){
      return false;
      }
    });
    // $('.identification_number').removeAttr('readonly')
      }
      else{
        $(".identification_number").val(value);
    $(".identification_number").parsley().validate();
        $('.identification_number').attr('readonly', 'readonly')
        $("#business_profile_form").find('.cancel_button').removeAttr('disabled');
        $("#business_profile_form").find('.submit_button').removeAttr('disabled');
      }
    });
  }
  Select2ClickeEvent();

  window.Select2Calls = function(){
    // $('.select2').select2({
   //      width: '360px'
   //    });

    $('.sub_industry').select2({
        placeholder: 'Select Sub Industry',
        maximumSelectionLength: 10
    });

    $('.pincode').select2({
        placeholder: 'Please enter 3 or more characters',
        minimumInputLength: 3,
        ajax: {
          type: "get",
          dataType: "script",
          url: window.location.origin + "/get_pincode_details",
          delay: 500,
          data: function(params){
            var ticket_id = "1142536";
            params.m = this
            return{
              pincode: params.term, ticket_id: ticket_id
            };
          },
          processResults: function(data, params) {
              var myResults;
              myResults = void 0;
              myResults = []
              $.each(JSON.parse(data) , function(index, val) {
         // console.log("Value: "+ val.id.split(" -")[0]);
         // console.log("TEXT: "+ val.name);
                myResults.push({
                  id: val.id.split(" -")[0],
                  text: val.name
                });
              });
              return {
                results: myResults
              };
          }
        }
      });


      $('.pincode').on('select2:select', function (e) {
        var data = e.params.data;
        var $t = $(this);
        var pincode_details = data["text"].split("-")[1].split(", ");
        var $select_city = $t.parent().parent().parent().find('.city_name');
        var $select_country = $t.parent().parent().parent().find('.country');
        var $select_state = $t.parent().parent().parent().find('.state');
        var $select_district = $t.parent().parent().parent().find('.district');
        var $select_pincode = $t.parent().parent().parent().find('.pincode');
        $select_state.val(pincode_details[2]);
        $select_district.val(pincode_details[1]);
      var $select_city_name = $t.parent().parent().parent().find('.city');
        // $select_pincode.val(data["text"].split("-")[0]).trigger('change.select2');
        $select_country.val("India");
        var form = $t.closest('form').attr("id");
      $select_state.parsley().validate();
        $select_district.parsley().validate();
      if ($select_city.length) {
        $select_city.val(pincode_details[0]);
          $select_city.parsley().validate();
      }
      $select_pincode.parsley().validate();
      if ($select_city_name.length) {
        $select_city_name.val(pincode_details[0]);
        $select_city_name.parsley().validate();
      }
      if ($select_country.length) {
        $select_country.parsley().validate();
      }
      SubmitAction();
      });
  }
  Select2Calls();

  window.ButtonEnable = function(){
    // $("#profile_kyc_form .floating-inputnew").click(function(){
    //   if ($(this).val() != ''){
    //     $(this).closest('form').find('.cancel_button').removeAttr('disabled');
    //     $(this).closest('form').find('.submit_button').removeAttr('disabled');
    //   }
    // });
    $("#profile_address_form .floating-inputnew").click(function(){
      if ($(this).val() != ''){
        $(this).closest('form').find('.cancel_button').removeAttr('disabled');
        $(this).closest('form').find('.submit_button').removeAttr('disabled');
       }
    });
    $("#profile_address_form .floating-inputnew").change(function(){
      if ($(this).val() != ''){
        $(this).closest('form').find('.cancel_button').removeAttr('disabled');
        $(this).closest('form').find('.submit_button').removeAttr('disabled');
       }
    });
    $("#business_profile_form .floating-inputnew").click(function(){
      if ($(this).val() != ''){
        $(this).closest('form').find('.cancel_button').removeAttr('disabled');
        $(this).closest('form').find('.submit_button').removeAttr('disabled');
       }
    });
    $("#business_profile_form .floating-inputnew").change(function(){
      if ($(this).val() != ''){
        $(this).closest('form').find('.cancel_button').removeAttr('disabled');
        $(this).closest('form').find('.submit_button').removeAttr('disabled');
       }
    });
    $("#business_profile_form .floating-inputnew").on('keypress', function(){
      if ($(this).val() != ''){
        $(this).closest('form').find('.cancel_button').removeAttr('disabled');
        $(this).closest('form').find('.submit_button').removeAttr('disabled');
       }
    });
    $("#address_profile_form .floating-inputnew").click(function(){
      if ($(this).val() != ''){
        $(this).closest('form').find('.cancel_button').removeAttr('disabled');
        $(this).closest('form').find('.submit_button').removeAttr('disabled');
       }
    });
    $("#address_profile_form .floating-inputnew").change(function(){
      if ($(this).val() != ''){
        $(this).closest('form').find('.cancel_button').removeAttr('disabled');
        $(this).closest('form').find('.submit_button').removeAttr('disabled');
       }
    });
  }
  ButtonEnable();
  window.BuildPayload = function(payload){
    if ($("#tab_identifier").attr('value') == 'kyc'){
      payload['attributes']['aadhar_number'] = $('.aadhar_number').val();
      payload['attributes']['pan_number'] = $('.pan_number').val();
    }
    else if ($("#tab_identifier").attr('value') == 'address')
    {
      payload['attributes']['address'] = $('.address').val()
      payload['attributes']['address_line_2'] = $('.address_line_2').val();
      payload['attributes']['pincode'] = $('.pincode').val();
      payload['attributes']['district'] = $('.district').val();
      payload['attributes']['city_name'] = $('.city_name').val();
      payload['attributes']['state'] = $('.state').val();
    }
    else if (($("#tab_identifier").attr('value') == 'business-profile') || ($("#tab_identifier").attr('value') == 'profile_business')){
      var id = $("#selected_organization_value").val();
      payload['id'] = id;
      if (payload['table_name'] == 'business_details'){
        payload['attributes']['business_type'] = $('.business_type').val();
        if ($('.business_name').is('select') == true){
          payload['attributes']['business_name'] = $('.business_name').find(":selected").text();
        }
        else{
          payload['attributes']['business_name'] = $('.business_name').val();
        }
        payload['attributes']['identifier_type'] = $('.identifier_type').val();
        payload['attributes']['identification_number'] = $('.identification_number').val();
        payload['attributes']['business_industry'] = $('.business_industry').val();
        payload['attributes']['sub_industry'] = $('.sub_industry').val();
        payload['attributes']['incorporation_date'] = $('.inc_date').val();
      }
      else if (payload['table_name'] == 'business_address'){
        payload['attributes']['flat_no'] = $('.flat_no').val();
        payload['attributes']['street'] = $('.street').val();
        payload['attributes']['pincode'] = $('.pincode').val();
        payload['attributes']['city'] = $('.city').val();
        payload['attributes']['district'] = $('.district').val();
        payload['attributes']['state'] = $('.state').val();
        payload['attributes']['country'] = $('.country').val();
        payload['attributes']['phone_number'] = $('.phone_number').val();
        payload['attributes']['e_mail'] = $('.e_mail').val();
        payload['attributes']['organization_id'] = $('.organization_id').val();
      }
    }
    return payload;
  }

  window.TicketMessageSubmitData = function(payload, url, header){
    $.ajax({
      type: "post",
      dataType: "json",
      processData: false,
      url: url,
      headers: {key: header["key"], token: header["token"] },
      data: payload,
      success: function (data) {
        var dashboard_key = $(".grc_dashboard_key").val();
        url = '/grc/messages/'+dashboard_key;
        var ticket_id = data['json']['ticket_id']
        var data = {};
        data['ticket_id'] = ticket_id
        RenderNextComponentMessages(data, url)
      },
      error: function(xhr,status,error){
      }
    });
  }

  window.MessagesFilter = function(){
    $("#ticket_message_account_filter").change(function(){
      $("#ticket_message_status_filter").val("");
      $("#ticket_message_service_filter").val("");
      var status_value = $("#ticket_message_status_filter").val();
      var organization_id = $("#ticket_message_account_filter").val();
      var service_id= $("#ticket_message_service_filter").val();

      var data = {};
      data['filter_by'] = {};
      data['filter_by']['account'] = organization_id
      data['filter_by']['ticket_status'] = status_value
      data['filter_by']['service'] = service_id
      var dashboard_key = $(".grc_dashboard_key").val();
      var url = '/grc/messages/'+dashboard_key
      RenderNextComponentMessages(data, url);
    });

    // $("#ticket_message_status_filter").click(function(){
    //   var status_value = $("#ticket_message_status_filter").val();
    //   var organization_id = $("#ticket_message_account_filter").val();
    //   var service_id= $("#ticket_message_service_filter").val();

    //   var data = {};
    //   data['filter_by'] = {};
    //   data['filter_by']['account'] = organization_id
    //   data['filter_by']['ticket_status'] = status_value
    //   data['filter_by']['service'] = service_id
    //   var url = '/dashboard_page?step=dashboard_messages';
    //   RenderNextComponentMessages(data, url);
    // });

    $("#ticket_message_status_filter").change(function(){
      $("#ticket_message_service_filter").val("");
      var status_value = $("#ticket_message_status_filter").val();
      var organization_id = $("#ticket_message_account_filter").val();
      var service_id= $("#ticket_message_service_filter").val();

      var data = {};
      data['filter_by'] = {};
      data['filter_by']['account'] = organization_id
      data['filter_by']['ticket_status'] = status_value
      data['filter_by']['service'] = service_id
      var dashboard_key = $(".grc_dashboard_key").val();
      var url = '/grc/messages/'+dashboard_key;
      // var url = '/dashboard_page?step=dashboard_messages';
      RenderNextComponentMessages(data, url);
    });

    $("#ticket_message_service_filter").click(function(){
      var status_value = $("#ticket_message_status_filter").val();
      var organization_id = $("#ticket_message_account_filter").val();
      var service_id= $("#ticket_message_service_filter").val();

      var data = {};
      data['filter_by'] = {};
      data['filter_by']['account'] = organization_id
      data['filter_by']['ticket_status'] = status_value
      data['filter_by']['service'] = service_id
      var dashboard_key = $(".grc_dashboard_key").val();
      var url = '/grc/messages/'+dashboard_key;
      RenderNextComponentMessages(data, url);
    });

    $("#ticket_message_service_filter").change(function(){
      var status_value = $("#ticket_message_status_filter").val();
      var organization_id = $("#ticket_message_account_filter").val();
      var service_id= $("#ticket_message_service_filter").val();

      var data = {};
      data['filter_by'] = {};
      data['filter_by']['account'] = organization_id
      data['filter_by']['ticket_status'] = status_value
      data['filter_by']['service'] = service_id
      var dashboard_key = $(".grc_dashboard_key").val();
      var url = '/grc/messages/'+dashboard_key;
      // var url = '/dashboard_page?step=dashboard_messages';
      RenderNextComponentMessages(data, url);
    });
  }
  MessagesFilter();

  window.logoUpload = function(){
    $("#logo_upload").click(function(){
      $("#logo_document_upload").trigger("click");
    });

    $("#logo_document_upload").change(function(){
      var attachment = this.files[0];
      if ((attachment.size <= 20971520) && ["jpg","jpeg","png"].includes(attachment.type.split("/")[1])){
        var formData = new FormData();
        formData.append('id', $("#selected_organization_value").val());
        formData.append('document_key', $("#document_key").attr('value'));
        formData.append('document', attachment, attachment.name);
        $.ajax({
          type: "post",
          dataType: "script",
          processData: false,
          contentType: false,
          url: window.location.origin + "/upload_logo_document",
          data: formData,
          success: function (data) {
            logoUpload();
            GlobalClickFunction();
          },
          error: function(xhr,status,error){
          }
        });
      }
      else{
        if ((attachment.size > 20971520) && ["jpg","jpeg","png"].includes(attachment.type.split("/")[1])){
          alert("Invalid file size, not more than 20MB is allowed");
        }
        else{
          alert("Invalid image type or unsupported image format");
        }
        GlobalClickFunction();
      }
    });
  }
  logoUpload();

  window.IdentifierType = function(){
    $('#business_profile_form .identifier_type').change(function(){
      var id = $('#organization_id').attr('value');
      if ((id != undefined) || (id != '')){
        var value = $(this).val();
        var payload = {};
        payload['id'] = $("#selected_organization_value").val();
        payload['attributes'] = {};
        payload['table_name'] = 'business_details';
        payload['tab_identifier'] = 'business-profile'
        payload['ticket_owner_id'] = "";
        payload['attributes']['business_type'] = $('.business_type').val();
        if ($('.business_name').is('select') == true){
          payload['attributes']['business_name'] = $('.business_name').find(":selected").text();
        }
        else{
          payload['attributes']['business_name'] = $('.business_name').val() || $('.non-editable_business_name').val();
        }
        payload['attributes']['identifier_type'] = $('.identifier_type').val();
        var vs_api_url = $('.environment_url').attr('value');
        var url = vs_api_url+'/api/v1/grc/business_details';
        var header = {};
        header["key"] = $('#api_key').attr('value');
        header["token"] = $('.authentication_token').attr('value');
        SubmitData(payload, url, header);
        var id = "profile_business";
        var step_name = getSectionName(id);
        var dashboard_key = $(".grc_dashboard_key").val();
        $.ajax({
          type: "post",
          dataType: "script",
          url: window.location.origin+ '/grc/'+step_name+'/'+dashboard_key,
          data: {payload: payload},
          success: function (data) {
            BusinessProfile();
            ButtonEnable();
            GlobalClickFunction();
            return true;
          },
          error: function(xhr,status,error){
            return false;
          }
        });
      }
    });
  }
  IdentifierType();

  window.RenderNextComponent = function(data, url){
    // $.ajax({
    //   type: "post",
    //   dataType: "script",
    //   url: url,
    //   data: {response: JSON.stringify(data)},
    //   success: function (data) {
    //     DashboardPage();
    //     ServicePage();
    //     PersonalDetailFunction();
    //     Select2Calls();
    //     FormSubmit();
    //     ButtonEnable();
    //     return false;
    //   },
    //   error: function(xhr,status,error){
    //     return false;
    //   }

    // window.location.reload(window.location.origin + url);
    // });
    var current_url = window.location.href;
    var params = current_url.split("?")[1];
    if ((params != "") && (params != undefined) && params.split("=").includes('ticket_owner_id')){
      window.location.href = window.location.origin + url +"?"+ params;
    }
    else{
      window.location.href = window.location.origin + url;
    }
  }

  window.RenderComponentMessage = function(data, url){
    var current_url = window.location.href;
    var params = current_url.split("?")[1];
    if ((params != "") && (params != undefined)){
      window.location.href = window.location.origin + url +"?"+ params;
    }
    else{
      window.location.href = window.location.origin + url;
    }
  }

  window.RenderNextComponentDashboard = function(data, url){
    $.ajax({
      type: "post",
      dataType: "script",
      url: url,
      data: {payload: data},
      success: function (data) {
        GlobalClickFunction();
        // DashboardPage();
        // ServicePage();
        // PersonalDetailFunction();
        // Select2Calls();
        // FormSubmit();
        ButtonEnable();
        return false;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  }

  window.RenderNextComponentBusiness = function(data, url){
    $.ajax({
      type: "post",
      dataType: "script",
      url: url,
      data: {payload: data},
      success: function (data) {
        GlobalClickFunction();
        PersonalDetailFunction();
        Select2Calls();
        FormSubmit();
        BusinessProfile();
        ButtonEnable();
        SettingsFunction();
        return true;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  }

  window.RenderNextComponentMessages = function(data, url){
    var current_url = window.location.href;
    var params = current_url.split("?")[1];
    if ((params != "") && (params != undefined) && params.split("=").includes('ticket_owner_id')){
      data["ticket_owner_id"] =  params.split("=")[1];
    }
    $.ajax({
      type: "post",
      dataType: "script",
      url: url,
      data: {payload: data},
      success: function (data) {
        // GlobalClickFunction();
        MessageDashboardFunction();
        MessagesFilter();
        ButtonEnable();
        return true;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  }
}

window.MessageDashboardFunction = function(){
  BackButton();
  var editorArea = document.querySelectorAll('.ckeditor_text_field');
  for(var i = 0; i < editorArea.length; ++i){
    // ClassicEditor.create(editorArea[i])
    CKEDITOR.replace( editorArea[i], {
      toolbar: [
        [ 'Cut', 'Copy', '-', 'Undo', 'Redo' ],      // Defines toolbar group without name.
        '/',                                          // Line break - next group will be placed in new line.
        { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript' ] },
        { name: 'paragraph', groups: [ 'list', 'indent', 'blocks', 'align', 'bidi' ], items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock' ] },
        '/',
        { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] }
      ]
    })

  //   ClassicEditor.create( editorArea[i], {
  //     // plugins: [
  //     //     EssentialsPlugin,
  //     //     AutoformatPlugin,
  //     //     BoldPlugin,
  //     //     ItalicPlugin,
  //     //     BlockQuotePlugin,
  //     //     HeadingPlugin,
  //     //     ImagePlugin,
  //     //     ImageCaptionPlugin,
  //     //     ImageStylePlugin,
  //     //     ImageToolbarPlugin,
  //     //     EasyImagePlugin,
  //     //     ImageUploadPlugin,
  //     //     LinkPlugin,
  //     //     ListPlugin,
  //     //     ParagraphPlugin,
  //     //     UploadAdapterPlugin
  //     // ],

  //     // So is the rest of the default configuration.
  //     toolbar: [
  //         'heading',
  //         'bold',
  //         'italic',
  //         'link',
  //         'bulletedList',
  //         'numberedList',
  //         'uploadImage',
  //         'blockQuote',
  //         'undo',
  //         'redo'
  //     ],
  //     image: {
  //         toolbar: [
  //             'imageStyle:inline',
  //             'imageStyle:block',
  //             'imageStyle:side',
  //             '|',
  //             'toggleImageCaption',
  //             'imageTextAlternative'
  //         ]
  //     }
  // } )
  }
  $("#ticket_message_service_filter").select2();

  $('.dashboard_ticket_messages').unbind().click(function(e){
    e.preventDefault();
    var ticket_id = $(this).attr('value');
    var data = {};
    data['ticket_id'] = ticket_id
    data['id'] = $("#selected_organization_value").val();
    var status_value = $("#ticket_message_status_filter").val();
    var organization_id = $("#ticket_message_account_filter").val();
    var service_id= $("#ticket_message_service_filter").val();
    data['filter_by'] = {};
    data['filter_by']['account'] = organization_id
    data['filter_by']['ticket_status'] = status_value
    data['filter_by']['service'] = service_id
    var dashboard_key = $(".grc_dashboard_key").val();
    var url = '/grc/messages/'+dashboard_key;
    // var url = "/dashboard_page?step=dashboard_messages";
    RenderNextComponentMessages(data, url);
  });

  $('#ck-delete').click(function(){
    $(".attachment_section").css("display", "none");
    $(".reply_to_ticket").css("display", "block");
  });

  $('.load_more_ticket_message').click(function(){
    var ticket_id = $(this).attr('data_ticket_id');
    var data = {};
    data['ticket_id'] = ticket_id
    data['load_more'] = 'true'
    data['id'] = $("#selected_organization_value").val();
    var status_value = $("#ticket_message_status_filter").val();
    var organization_id = $("#ticket_message_account_filter").val();
    var service_id= $("#ticket_message_service_filter").val();
    data['filter_by'] = {};
    data['filter_by']['account'] = organization_id
    data['filter_by']['ticket_status'] = status_value
    data['filter_by']['service'] = service_id
    // var url = "/dashboard_page?step=dashboard_messages";
    var dashboard_key = $(".grc_dashboard_key").val();
    var url = '/grc/messages/'+dashboard_key;
    RenderNextComponentMessages(data, url);
  });

  $('.cksend-btn').click(function(){
    var formData = new FormData();
    var ticket_id = $(this).attr('data-ticket_id')
    var id = $(this).attr('data_ticket_message_id')
    // var content = $("#editor_"+id).next().find('.ck-content').html();
    var content = CKEDITOR.instances["editor_"+id].getData()
    var status_value = $("#ticket_message_status_filter").val();
    var organization_id = $("#ticket_message_account_filter").val();
    var service_id= $("#ticket_message_service_filter").val();
    var data = {};
    data['filter_by'] = {};
    data['filter_by']['account'] = organization_id
    data['filter_by']['ticket_status'] = status_value
    data['filter_by']['service'] = service_id
    data['ticket_message'] = {}
    data['ticket_message']['content'] = content;
    data['ticket_message']['ticket_id'] = ticket_id;
    data['ticket_message']['ticket_attachment_attributes'] = {};
    formData.append('content', content);
    formData.append('ticket_id', ticket_id);
    formData.append('account', organization_id);
    formData.append('ticket_status', status_value);
    formData.append('service', service_id);

    var first_attachment_length = $("#attachment-field-take_"+id).prop('files').length

    var ticket_attachment_length = $(".ticket_attachment_files").length

    var attachment_length = first_attachment_length + ticket_attachment_length
    formData.append('attachment_count', attachment_length);
    for(var i=0; i<=(first_attachment_length - 1); i++){
      file = $("#attachment-field-take_"+id).prop('files')[i]
      if (file != undefined){
        var file_name = file.name
        var extension = file_name.split(".")[file_name.split(".").length - 1]
        if ((file.size <= 20971520) && ["jpg","jpeg","png","pdf", "xlsx", "doc", "docx", "csv", "pptx", "xls"].includes(extension)){
          formData.append('document_'+i, file, file_name);
        }
        else{
          alert("Invalid file size, not more than 20MB is allowed");
        }
      }
    }

    var document_count = first_attachment_length;
    for(var i=0; i<=(attachment_length - 1); i++){
      var attachment = $(".ticket_attachment_files")[i];
      if (attachment != undefined){
        if (attachment.files.length > 0){
          for(var j=0; j<=(attachment.files.length - 1); j++){
            var file = attachment.files[j]
            var file_name = attachment.files[j].name
            if (file != undefined){
              var extension = file_name.split(".")[file_name.split(".").length - 1]
              if ((attachment.size <= 20971520) && ["jpg","jpeg","png","pdf", "xlsx", "doc", "docx", "csv", "pptx", "xls"].includes(extension)){
                var document_count = document_count + 1;
                formData.append('document_'+document_count, file, file_name);
              }
              else{
                alert("Invalid file size, not more than 20MB is allowed");
              }
            }
          }
        }
      }
    }

    $.ajax({
      type: "post",
      dataType: "script",
      processData: false,
      contentType: false,
      data: formData,
      url: '/send_ticket_message_grc',
      success: function (data) {
        MessageDashboardFunction();
        MessagesFilter();
        return true;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  });
  
  $('.redirection_url').click(function(){
    var params = commonTrackingDetails(this);
    vsTrackEvent({
      eventName: "user_redirection",
      params: Object.assign(params, {
        source: "Email reply",
        page_name: "Messages",
      })
    });
    var dashboard_key = $(".grc_dashboard_key").val();
    var step_name = getSectionName("dashboard_home");
    var url = '/grc/'+step_name+'/'+dashboard_key;
    window.location.href = window.location.origin + url;
  });

  $('.inbox_content').click(function(e){
    var id = $(this).attr('value')
    if ($("#mail_content_"+id).css('display') == 'block'){
      $('#mail_content_'+id).css('display', 'none');
      $("#header_"+id).css('display', 'block');
      $('#inbox_mail_'+id).find('.mail_dropdown').css('transform','rotate(360deg)')
    } else {
      $(this).addClass('active')
      $('#mail_content_'+id).css('display', 'block');
      $("#header_"+id).css('display', 'none');
      $('#inbox_mail_'+id).find('.mail_dropdown').css('transform','rotate(180deg)')
    }
    const isPreviouslyActive = $(this).hasClass('active');
    const attachment = $(this).find(".attachment_section")[0];
    const iconLink = $(this).find(".reply_to_ticket.icon-link")[0];
    const textLink = $(this).find(".reply_to_ticket.text-link");
    if(e.target.isSameNode(iconLink)) {
      $(attachment).css("display", "block");
      textLink.css("display", "none");
    } else {
      if(!($(attachment).css("display") !== "none") || !(isPreviouslyActive)) {
        $(attachment).css("display", "none");
        textLink.css("display", "block");
      }
    }
  });

  $('.mail_dropdown').click(function(e){
    var id = $(this).attr('value')
    if ($("#mail_content_"+id).css('display') == 'block'){
      $('#mail_content_'+id).css('display', 'none');
      $("#header_"+id).css('display', 'block');
      $(this).css('transform','rotate(360deg)')
    } else {
      $(this).addClass('active')
      $('#mail_content_'+id).css('display', 'block');
      $("#header_"+id).css('display', 'none');
      $(this).css('transform','rotate(180deg)')
    }
  });

  $('.reply_to_ticket').click(function(event){
      // $('.inbox_mail').click();
      var id = $(this).attr('value')
      const inboxMail = $(this).closest(".inbox_mail");
      inboxMail.find(".attachment_section").css("display", "block");
      $(this).next().next().css('display', 'block')
      $("#mail_content_"+id).css('display', 'block')
      $("#header_"+id).css('display', 'none');
      $('#inbox_mail_'+id).find('.mail_dropdown').css('transform','rotate(180deg)')
      if(this.classList.contains("text-link")){
        $(this).css("display", "none")
        event.stopPropagation();
      }
      var list_ele, multi_selector;
      list_ele = inboxMail.find('div[id=files_list_take_'+id+']')[0];
      multi_selector = new NewMultiSelector(list_ele, id);
      multi_selector.addElement($('input[id=attachment-field-take_'+id+']:visible')[0]);
      multi_selector.addElement(inboxMail.find('input[id=attachment-field-take_'+id+']')[0]);
  });
}

window.GlobalClickFunction = function(){
  ProfilePage();
  DashboardClickFunction();
  MessageDashboardFunction();
  setDueDates();
  switch (new Date().getDay()) {
    case 0:
      var start = "+0d";
      var end = "+6d";
      break;
    case 1:
      var start = "-1d";
      var end = "+5d";
      break;
    case 2:
      var start = "-2d";
      var end = "+4d";
      break;
    case 3:
      var start = "-3d";
      var end = "+3d";
      break;
    case 4:
      var start = "-4d";
      var end = "+2d";
      break;
    case 5:
      var start = "-5d";
      var end = "+1d";
      break;
  }
  $("#datepicker").datepicker({
    dateFormat: 'dd-mm-yy',
    timepicker: false,
    todayHighlight: true,
    closeOnDateSelect: false,
    changeMonth: true,
    changeYear: true,
    yearRange: "-100:+20",
    autoclose: true,
    startDate: start,
    endDate: end
  });

  $("#header_function li a").click(function(e) {
    var dashboard_key = $(".grc_dashboard_key").val();
    $("#header_function li").removeClass('active');
    $(this).parent().addClass('active')
    if ($(this).attr('href') == "#dashboard_messages"){
      var payload = {};
      payload['id'] = $("#selected_organization_value").val();
      // var url = '/dashboard_page?step=dashboard_messages';
      // RenderNextComponentMessages(payload, url);
      var step_name = getSectionName("dashboard_messages");
      var url = '/grc/'+step_name+'/'+dashboard_key;
      // $("#overlayDiv").show();
      RenderNextComponent(payload, url);
      return false;
    }
    else if ($(this).attr('href') == "#dashboard_interest"){
      var data = {};
      data['filter_by'] = {};
      var ticket_owner_id = "";
      var current_url = window.location.href;
      var params = current_url.split("?")[1];
      if ((params != "") && (params != undefined) && params.split("=").includes('ticket_owner_id')){
        ticket_owner_id = params.split("=")[1];
      }
      data['ticket_owner_id'] = ticket_owner_id;
      var step_name = getSectionName("dashboard_interest");
      var url = '/grc/'+step_name+'/'+dashboard_key;
      // $("#overlayDiv").show();
      RenderNextComponent(data, url);
      return false;
    }
    else if ($(this).attr('href') == "#dashboard_services"){
      var data = {};
      data['filter_by'] = {};
      // var url = '/dashboard_page?step=dashboard_services';
      var step_name = getSectionName("dashboard_services");
      var url = '/grc/'+step_name+'/'+dashboard_key;
      RenderNextComponent(data, url);
      return false;
    }
    else if ($(this).attr('href') == "#dashboard_home"){
      var data = {};
      data['filter_by'] = {};
      var step_name = getSectionName("dashboard_home");
      var url = '/grc/'+step_name+'/'+dashboard_key;
      RenderNextComponent(data, url);
      return false;
    }
    else{
      var id = $(this).attr('href')
      var value = $(id).css('display')

      if (value == 'none'){
        $("#profile_info").css('display','none')
        $("#db_settings_list").css('display','none')
      }
      else{
        $("#profile_info").css('display','none')
        $("#db_settings_list").css('display','none')
        // $(id).css('display','none')
      }
      $(id).css('display','block')
      return false;
    }
  });

  $('.grc-btn-popup').click(function(){
    $('.grc-btn-popup').removeClass('active');
    $(this).addClass('active');
  });
  $('#close_tab_popup').click(function(){
    $('.no-active-grc').removeClass('active');
    $('.no-tab-active').removeClass('grc_tab-active');
    $('.active_tab_grc').addClass('grc_tab-active')
  });
  $('#interested_btn_grc_first').click(function(){
    $('.no-active-grc').removeClass('active');
    $('.no-tab-active').removeClass('grc_tab-active');
    $('.active_tab_grc').addClass('grc_tab-active')
  });
  $('#interested_btn_grc_second').click(function(){
    $('.no-active-grc').removeClass('active');
    $('.no-tab-active').removeClass('grc_tab-active');
    $('.active_tab_grc').addClass('grc_tab-active')
  });
  $('#interested_txt_grc_first').click(function(){
    $('.no-active-grc').removeClass('active');
    $('.no-tab-active').removeClass('grc_tab-active');
    $('.active_tab_grc').addClass('grc_tab-active')
  });
  $('#interested_txt_grc_second').click(function(){
    $('.no-active-grc').removeClass('active');
    $('.no-tab-active').removeClass('grc_tab-active');
    $('.active_tab_grc').addClass('grc_tab-active')
  });
  $('.service_mapping_detail').click(function(){
    $("#what_is_service_mapping").show();
  });

  $('.close_popup').click(function(){
    $(this).closest('.password-popupoverlay').hide();
  });

  $('.message_help_close').click(function(){
    $('#help_user').css('display', 'none')
  });

  window.openHelppopup = function() {
    $('#help_user').css('display', 'block')
  }

  $('#db_settings_list li a').click(function(e){
    e.preventDefault();
    var payload = {};
    var step = $(this).attr('href').split('#')[1];
    var dashboard_key = $(".grc_dashboard_key").val();
    var step_name = getSectionName(step);
    var url = '/grc/'+step_name+'/'+dashboard_key;
    RenderNextComponent(payload, url);
    return false;
  });
  $('.db_settings_list_new ul li a').click(function(e){
    e.preventDefault();
    var payload = {};
    var step = $(this).attr('href').split('#')[1];
    var dashboard_key = $(".grc_dashboard_key").val();
    var step_name = getSectionName(step);
    var url = '/grc/'+step_name+'/'+dashboard_key;
    RenderNextComponent(payload, url);
    return false;
  });
  // $('#user_and_roles').click(function(){
  //   var close_popup = $(this).attr('value');
  //   var payload = {};
  //   $.ajax({
  //     type: "post",
  //     dataType: "script",
  //     url: '/dashboard_page?step=user_and_roles&close_popup='+close_popup,
  //     data: payload,
  //     beforeSend: function(){
  //       $("#overlayDiv").show();
  //     },
  //     success: function (data) {
  //       $("#overlayDiv").hide();
  //       GlobalClickFunction();
  //       // PersonalDetailFunction();
  //       return true;
  //     },
  //     error: function(xhr,status,error){
  //       return false;
  //     }
  //   });
  // });

  // $('#help_settings').click(function(){
  //   var close_popup = $(this).attr('value');
  //   var payload = {};
  //   $.ajax({
  //     type: "post",
  //     dataType: "script",
  //     url: '/dashboard_page?step=help_settings&close_popup='+close_popup,
  //     data: payload,
  //     beforeSend: function(){
  //       $("#overlayDiv").show();
  //     },
  //     success: function (data) {
  //       $("#overlayDiv").hide();
  //       GlobalClickFunction();
  //       // PersonalDetailFunction();
  //       return true;
  //     },
  //     error: function(xhr,status,error){
  //       return false;
  //     }
  //   });
  // });

  // $('#contact_us_settings').click(function(){
  //   var close_popup = $(this).attr('value');
  //   var payload = {};
  //   $.ajax({
  //     type: "post",
  //     dataType: "script",
  //     url: '/dashboard_page?step=contact_us_settings&close_popup='+close_popup,
  //     data: payload,
  //     beforeSend: function(){
  //       $("#overlayDiv").show();
  //     },
  //     success: function (data) {
  //       $("#overlayDiv").hide();
  //       GlobalClickFunction();
  //       // PersonalDetailFunction();
  //       return true;
  //     },
  //     error: function(xhr,status,error){
  //       return false;
  //     }
  //   });
  // });

  window.getOrganization = function(){
    if($('.get_organizations').is(':visible')){
      $(".get_organizations").css("display", "none");
    }
    else{
      $(".get_organizations").css("display", "block");
    }
  }

  window.getDashboard = function(event){
    $(".get_organizations").css("display", "none");
    var organization_id = $('#selected_organization_value').val();
    if (parseInt(event.id) != parseInt(organization_id)){
      $("#switching_screen").css("display", "block");
      $("#switching_screen b").text(event.textContent);
      // var path_name = window.location.pathname;
      // var url = window.location.href;
      // var env_url = url.replace(path_name, '');
      var env_url = window.location.origin;
      //var grc_end_point = "/dashboard_page?organization_id="+event.id;
      var grc_end_point = "/grc/dashboard/"+event.getAttribute("data-attribute-key");
      var current_url = window.location.href;
      var params = current_url.split("?")[1];
      if ((params != "") && (params != undefined) && params.split("=").includes('ticket_owner_id')){
        window.location.href = env_url+grc_end_point+"?"+params;
      }
      else{
        window.location.href = env_url+grc_end_point;
      }
    }
  }

  window.getUserandRoles = function(){
    var dashboard_key = $(".grc_dashboard_key").val();
    var payload = {};
    var url = '/grc/user-roles/'+dashboard_key;
    RenderNextComponent(payload, url);
  }

  window.getServices = function(){
    var params = commonTrackingDetails(this);

    vsTrackEvent({
      eventName: "explored_services_cta_clicked",
      params: Object.assign(params, {
        source: "My services",
        page_name: "Dashboard",
      })
    });


    $("#header_function li").removeClass('active');
    $("#header_function li a[href='#dashboard_services']").parent().addClass('active');
    var dashboard_key = $(".grc_dashboard_key").val();
    var payload = {};
    var url = '/grc/services/'+dashboard_key;
    RenderNextComponent(payload, url);
    return false;
  }

  window.renderComponent = function(secion) {
    $("#header_function li").removeClass('active');
    $("#header_function li a[href='#dashboard_interest']").parent().addClass('active');
    var dashboard_key = $(".grc_dashboard_key").val();
    var payload = {};
    var url = '/grc/my-interests/'+dashboard_key;
    RenderNextComponent(payload, url);
  }
};

window.getSectionName = function (step){
  switch (step) {
    case 'my_service':
      return "my-services"
      break;
    case 'payment':
      return "payments"
      break;
    case 'compliance_calendar':
      return "calendar"
      break;
    case 'dashboard_messages':
      return "messages"
      break;
    case 'dashboard_interest':
      return "my-interests"
      break;
    case 'dashboard_services':
      return "services"
      break;
    case 'dashboard_home':
      return "dashboard"
      break;
    case 'user_and_roles':
      return "user-roles"
      break;
    case 'help_tab':
      return "help"
      break;
    case 'help_settings':
      return "help"
      break;
    case 'contact_us_settings':
      return "support"
      break;
    case 'contact_us':
      return "support"
      break;
    case 'profile_business':
      return "business-profile"
      break;
    case 'profile':
      return "personal-info"
      break;
    case 'profile_home':
      return "personal-info"
      break;
    case 'profile_address':
      return "address"
      break;
    case 'profile_kyc':
      return "kyc"
      break;
    default:
      return step
      break;
  }
}

window.FormSubmit = function(){
  SubmitAction();
  // GetMandatoryFields();
  // SubmitData();
}

window.ProfilePage = function(){
  window.profileAddpopup = function(popupid) {
    $(popupid).addClass('show');
  }

  window.chooseAccount = function() {
    window.location.href = "/grc/choose-account"
  }

  window.addEmailsendotp = function() {
    if ($("#profile_add_email_box").parsley().validate() == true){
      var email = $(".pro_add_email").val();
      $.ajax({
        url: window.location.origin + '/grc/my_profile_home',
        type: 'POST',
        dataType: "script",
        data: {email: email, method_type: "Post", method_name: "grc/add_email_and_mobile_number", action_value: "add_email"},
        success: function (data) {
          GlobalClickFunction();
          return true;
        },
        error: function(xhr,status,error){
          return false;
        }
      });
    };
  }

  window.addMobilesendotp = function() {
    if ($("#send_mobile_OTP").parsley().validate() == true){
      var mobile = $(".add_mobile_number").val();
      $.ajax({
        url: window.location.origin + '/grc/my_profile_home',
        type: 'POST',
        dataType: "script",
        data: {mobile_number: mobile, method_type: "Post", method_name: "grc/add_email_and_mobile_number", action_value: "add_mobile_number"},
        success: function (data) {
          GlobalClickFunction();
          return true;
        },
        error: function(xhr,status,error){
          return false;
        }
      });
    };
  }

  window.changePassword = function() {
    $("#password_error").hide();
    $("#confirm_password_error").hide();
    $("#password_char_error").hide();
    $("#confirm_password_char_error").hide();
    if ($("#change_password").parsley().validate() == true){
      var old_password = $(".old_password").val();
      var new_password = $(".new_password").val();
      var confirm_password = $(".confirm_password").val();
      if (new_password != confirm_password) {
        $('#mismatched_pass').show();
      } else if (/^[^-\s]{6,20}$/.test(new_password)) {
        //if (/^[^-\s]{6,20}$/.test(password)) {
          $.ajax({
            url: window.location.origin + '/grc/my_profile_home',
            type: 'POST',
            dataType: "script",
            data: {old_password: old_password, new_password:  new_password, confirm_password: confirm_password, method_type: "Post", method_name: "grc/password_reset", action_value: "password_reset"},
            success: function (data) {
              GlobalClickFunction();
              return true;
            },
            error: function(xhr,status,error){
              return false;
            }
          });
        // } else {
        //   $("#password_error").show();
        //   $("#confirm_password_error").show();
        // }
      } else {
        $("#password_char_error").show();
        $("#confirm_password_char_error").show();
      }
    };
  };

  window.profileUpload = function() {
    $("#profile_image_upload").trigger("click");
    $("#profile_image_upload").change(function(){
      var attachment = this.files[0];
      if ((attachment.size <= 5242880) && ["jpg","jpeg","png"].includes(attachment.type.split("/")[1])){
        var formData = new FormData();
        formData.append('file_path', attachment, attachment.name);
        formData.append('action_value', 'upload_profile_image');
        formData.append('method_name', 'grc/upload_profile_image');
        formData.append('method_type', 'Post');
        $.ajax({
          type: "post",
          dataType: "script",
          processData: false,
          contentType: false,
          url: window.location.origin + "/grc/my_profile_home",
          data: formData,
          success: function (data) {
            GlobalClickFunction();
            return true;
          },
          error: function(xhr,status,error){
            return false;
          }
        });
      }
      else{
        alert("Invalid file size, not more than 5MB is allowed");
      }
    });
  }

  window.displayButton = function(){
    $("#name_validation").hide();
    $('#save').removeClass("hide");
    $('#cancel').removeClass("hide");
  }

  window.cancelChanges = function(){
    $.ajax({
      url: window.location.origin + '/grc/my_profile_home',
      type: 'POST',
      dataType: "script",
      data: {identifier: "cancel"},
      success: function (data) {
        GlobalClickFunction();
        return true;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  }

  window.openDeletePopup = function(popupid, value_selected) {
    $('.more_box').css('display','none');
    $(popupid).addClass('show');
    $(popupid+" input").val(value_selected);
  };

  window.deleteUsingPopup = function(popupid, value_selected){
    $('.number_more_box').css('display','none');
    $(popupid).addClass('show');
    $(popupid+" input").val(value_selected);
  }

  window.cancelEmailMobileDelete = function(popupid) {
    $(popupid).css('display','none');
  }

  window.confirmEmailDelete = function(event) {
    // var email = document.getElementsByClassName('verify_email_now')[0].id
    var email = $('.email_value_to_delete').val();
    $.ajax({
      url: window.location.origin + '/grc/my_profile_home',
      type: 'POST',
      dataType: "script",
      data: {email: email, method_type: "Post", method_name: "grc/destroy_email_and_mobile_number", action_value: "destroy_email_and_mobile_number"},
      success: function (data) {
        GlobalClickFunction();
        return true;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  }

  window.confirmNumberDelete = function() {
    var mobile_number = $(".mobile_num_value").val();
    $.ajax({
      url: window.location.origin + '/grc/my_profile_home',
      type: 'POST',
      dataType: "script",
      data: {mobile_number: mobile_number, method_type: "Post", method_name: "grc/destroy_email_and_mobile_number", action_value: "destroy_email_and_mobile_number"},
      success: function (data) {
        GlobalClickFunction();
        return true;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  }

  window.makeMobilePrime = function(number) {
    // $('.number_more_box').css('display','none');
    $.ajax({
      url: window.location.origin + '/grc/my_profile_home',
      type: 'POST',
      dataType: "script",
      data: {mobile_number: number, method_type: "Post", method_name: "grc/make_user_email_or_mobile_communication_primary", action_value: "make_email_mobile_primary"},
      success: function (data) {
        GlobalClickFunction();
        return true;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  }

  window.makeEmailPrime = function(email) {
    $('.more_box').css('display','none');
    $.ajax({
      url: window.location.origin + '/grc/my_profile_home',
      type: 'POST',
      dataType: "script",
      data: {email: email, method_type: "Post", method_name: "grc/email_or_mobile_make_primary_pop_up", action_value: "make_email_mobile_primary", make_primary: "true"},
      success: function (data) {
        GlobalClickFunction();
        return true;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  }

  window.closeOtpModel = function(popupId){
    $(popupId).hide();
    var payload = {};
    var dashboard_key = $(".grc_dashboard_key").val();
    var step_name = "personal-info"
    var url = '/grc/'+step_name+'/'+dashboard_key;
    RenderNextComponent(payload, url);
  }

    // verify email pop-up

  window.emailOtpVerification = function() {
    var email = $(".added_user_email").val() || added_user_email.value;
    var grc_otp_first = $("#digit-1").val();
    var grc_otp_second = $("#digit-2").val();
    var grc_otp_third = $("#digit-3").val();
    var grc_otp_fourth = $("#digit-4").val();
    var otp = grc_otp_first + grc_otp_second + grc_otp_third + grc_otp_fourth
    if ((otp == "") || (otp == undefined)) {
      $("#enter_otp").show();
    }else{
      $.ajax({
        url: window.location.origin + '/grc/my_profile_home',
        type: 'POST',
        dataType: "script",
        data: {email_otp: otp, email: email, method_type: "Post", method_name: "grc/verify_email_and_mobile_number", action_value: "verify_email_and_mobile_number"},
        success: function (data) {
          GlobalClickFunction();
          ProfilePage();
          return true;
        },
        error: function(xhr,status,error){
          return false;
        }
      });
    }
  }

  window.resendEmailOtp = function() {
    $("#enter_otp").hide();
    $("#invalid_otp_entered").css('display','none');
    var email = $(".added_user_email").val() || added_user_email.value;
    $.ajax({
      url: window.location.origin + '/grc/my_profile_home',
      type: 'POST',
      dataType: "script",
      data: {email: email, method_type: "Post", method_name: "grc/resend_otp_email_and_mobile_number", action_value: "resend_otp"},
      success: function (data) {
        GlobalClickFunction();
        ProfilePage();
        return true;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  }

  window.mobileOtpVerification = function() {
    var mobile = $(".user_mobile_number").val() || user_mobile_number.value;
    var grc_otp_first = $("#digit-1").val();
    var grc_otp_second = $("#digit-2").val();
    var grc_otp_third = $("#digit-3").val();
    var grc_otp_fourth = $("#digit-4").val();
    var otp = grc_otp_first + grc_otp_second + grc_otp_third + grc_otp_fourth
    if ((otp == "") || (otp == undefined)) {
      $("#enter_otp_alert").show();
    } else {
      $.ajax({
        url: window.location.origin + '/grc/my_profile_home',
        type: 'POST',
        dataType: "script",
        data: {mobile_number: mobile, mobile_otp: otp, method_type: "Post", method_name: "grc/verify_email_and_mobile_number", action_value: "verify_mobile_otp"},
        success: function (data) {
          return true;
        },
        error: function(xhr,status,error){
          return false;
        }
      });
    }
  }

  window.resendMobileOtp = function() {
    $("#enter_otp").hide();
    $("#invalid_otp_entered").css('display','none');
    var mobile = $(".user_mobile_number").val() || user_mobile_number.value;
    $.ajax({
      url: window.location.origin + '/grc/my_profile_home',
      type: 'POST',
      dataType: "script",
      data: {mobile_number: mobile, method_type: "Post", method_name: "grc/resend_otp_email_and_mobile_number", action_value: "resend_otp"},
      success: function (data) {
        GlobalClickFunction();
        ProfilePage();
        return true;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  }

  window.verifyEmailnow = function(email) {
    $.ajax({
      url: window.location.origin + '/grc/my_profile_home',
      type: 'POST',
      dataType: "script",
      data: {email: email, method_type: "Post", method_name: "grc/verify_added_email_and_mobile_number", action_value: "add_email"},
      success: function (data) {
        GlobalClickFunction();
        ProfilePage();
        return false;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  }

  window.verifyMobilenow = function(mobile_number) {
    $.ajax({
      url: window.location.origin + '/grc/my_profile_home',
      type: 'POST',
      dataType: "script",
      data: {mobile_number: mobile_number, method_type: "Post", method_name: "grc/verify_added_email_and_mobile_number", action_value: "add_mobile_number"},
      success: function (data) {
        GlobalClickFunction();
        ProfilePage();
        return false;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  }

  window.emailMakeprimaryandDelete = function(action) {
    $('.more_box').css('display','none');
    if (action.nextElementSibling.style.display == "none"){
      var identifier = action.id;
      $(".opt-popup-email-"+identifier).css('display','block');
      $('html').click(function(e) {
       if(!$(e.target).hasClass('more_box') && !$(e.target).hasClass('email_make_primary_and_delete'))
       {
       $('.more_box').css('display','none');
       }
     });
    }
    else{
      $('.more_box').css('display','none');
    }
  }

  window.mobileMakeprimaryandDelete = function(action) {
    $('.number_more_box').css('display','none');
    if (action.nextElementSibling.style.display == "none"){
      var identifier = action.id;
      $(".opt-popup-mobile-"+identifier).css('display','block');
       $('html').click(function(e) {
       if(!$(e.target).hasClass('more_box') && !$(e.target).hasClass('mobile_make_primary_and_delete'))
       {
       $('.number_more_box').css('display','none');
       }
     });
    }
    else{
      $('.number_more_box').css('display','none');
    }
  }

  window.checkPopupInput = function() {
    $("#password_exist").hide();
    $("#mismatched_pass").hide();
    var confirm_password = $(".confirm_password").val();
    if (/^(?=.*[~!@#$^&*()])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[\W])[a-zA-Z0-9~!@#$^&*()]{6,20}$/.test(confirm_password)) {
      $("#confirm_password_tick").show();
    } else {
      $("#confirm_password_tick").hide();
    }
  }

  window.passwordExist = function() {
    $("#password_error").hide();
    $("#password_char_error").hide();
    $("#mismatched_pass").hide();
    var new_password = $(".new_password").val();
    if (/^(?=.*[~!@#$^&*()])(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])(?=.*[\W])[a-zA-Z0-9~!@#$^&*()]{6,20}$/.test(new_password)) {
      $("#password_tick").show();
    } else {
      $("#password_tick").hide();
    }
  }

  window.confirmPasswordExist = function() {
    $("#confirm_password_error").hide();
    $("#confirm_password_char_error").hide();
    $("#mismatched_pass").hide();
  }

  window.dateValidation = function(){
    var today = new Date().toISOString().split('T')[0];
    document.getElementsByName("dob")[0].setAttribute('max', today);
  }

  window.myProfilePage = function(){
    $("#profile_update_popup").hide();
    $("#profile_success_popup").hide();
    var close_popup = $(this).attr('value');
    var payload = {};
    var dashboard_key = $(".grc_dashboard_key").val();
    var step_name = getSectionName("profile");
    var url = '/grc/'+step_name+'/'+dashboard_key;
    RenderNextComponent(payload, url);
  }

  window.getMyprofileinfo = function(){
    var ticket_owner_id = "";
    var current_url = window.location.href;
    var params = current_url.split("?")[1];
    if ((params != "") && (params != undefined) && params.split("=").includes('ticket_owner_id')){
      ticket_owner_id = params.split("=")[1];
    }
    $.ajax({
      url: window.location.origin + '/grc/my_profile_home',
      type: 'POST',
      dataType: "script",
      data: {identifier: "my_profile_info", method_type: "Get", method_name: "grc/get_profile_info", action_value: "get_profile_info", ticket_owner_id: ticket_owner_id},
      success: function (data) {
        // GlobalClickFunction();
        return true;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  }

  window.invite_user_role_flow = function(event) {
    var form_id = event.closest('form').id;
   // console.log(form_id);
    if ($("#"+form_id).parsley().validate() == true){
      var inputData = {};
      var input_count = event.closest('form').length;
      for (var i = 0; i < (input_count - 1); i++) {
        if (event.closest('form')[i].name != ""){
          inputData[event.closest('form')[i].name] = event.closest('form')[i].value;
        }
      }
      inputData['organization_id'] = $("#selected_organization_value").val();
      $.ajax({
        type: "post",
        dataType: "script",
        url: window.location.origin + "/organization_user_add",
        data: inputData,
        success: function (data) {
          $("#add_user_popup").css("display", "none");
          GlobalClickFunction();
          PersonalDetailFunction();
          return true;
        },
        error: function(xhr,status,error){
          return false;
        }
      });
    }
    else{
      return false;
    }
  }

  window.deleteUserRole = function(event){
    var user_name = event.name;
    $("#delete_user").css("display", "block");
    $("#delete_user p").text("Are you sure you want to remove "+user_name+"?");
    $("#delete_user input").val(event.id);
  }

  window.deleteUser = function(event){
    var id = $(".get_organization_role_id").val();
    $.ajax({
      type: "post",
      dataType: "script",
      url: window.location.origin + "/organization_delete_user",
      data: {id: id},
      success: function (data) {
        $("#delete_user").css("display", "none");
        GlobalClickFunction();
        PersonalDetailFunction();
        return true;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
  }
}

$(document).ready(function(){
  $( document ).on('turbolinks:load', function() {
    GlobalClickFunction();
  });
  GlobalClickFunction();
});

window.openHelpModal = function(title, description) {
  $("#open_help_popup").css("display", "block");
  $("#open_help_popup .popup-form h3").text(title);
  $("#open_help_popup p").text(description);
}


window.closeHelpModal = function(data) {
  $("#open_help_popup").css("display", "none");
}

window.closeSuccessMessage = function(event) {
  event.parentElement.style.display = "none";
}

// window.enableActionButtons = function(event){

//   var form_id = event.closest('form').id;
//   // $("#"+form_id)
// }

window.add_user_popup = function() {
  $("#add_user_popup").css('display','block')
}

window.close_user_role_popup = function() {
  $("#add_user_popup").css('display', 'none')
}

  window.showOrganizationtickets = function(data){
    var entity = $("#selected_entity").val();
    const replaceAll = (string, search, replace) => {
      return string.split(search).join(replace);
    };
    data = JSON.parse(replaceAll(data, "=>", ":"));
    for (var i = 0; i < data.length; i++) {
      if (data[i]["name"] == entity){
        $("#tickets_list").html('');
        var tickets = data[i]["organization_tickets"];
        for (var j = 0; j < tickets.length; j++){
          $("#tickets_list").append('<option value="'+ tickets[j]["service_name"] +'">'+ tickets[j]["service_name"] +'</option>')
        }
      }
    }
  }

  window.supportSubmit = function(){
    var entity = $("#selected_entity").val();
    var service = $("#tickets_list").val();
    var message = $(".message").val();
    var message_length = message.split(' ').length;
    if (message_length < 50){
      $("#min-length-alert").show();
    }else if (message_length > 100) {
      $("#max-length-alert").show();
    }else if (message_length == 0) {
      $("#empty-alert").show();
    }
    else {
    $.ajax({
      url: window.location.origin + '/grc/my_profile_home',
      type: 'POST',
      dataType: "script",
      data: {entity_name: entity, service_name: service, message: message, method_type: "Post", method_name: "grc/send_support_request", action_value: "send_support_request"},
      success: function (data) {
        $("#overlayDiv").hide();
        $('button').prop('disabled',true);
        $(".message").val('');
        GlobalClickFunction();
        return true;
      },
      error: function(xhr,status,error){
        return false;
      }
    });
    }
  }

  window.enableSave = function(){
    var entity = $("#selected_entity").val();
    var service = $("#tickets_list").val();
    var message = $(".message").val();
    $("#min-length-alert").hide();
    $("#max-length-alert").hide();
    $("#empty-alert").hide();
    if (entity.length == 0 || service.length == 0 || message.length == 0) {
      $('button').prop('disabled',true);
    } else {
      $('button').prop('disabled',false);
    }
  }

function MultiSelector( list_target, max ){

    // Where to write the list
    this.list_target = list_target;
    // How many elements?
    this.count = 0;
    // How many elements?
    this.id = 0;
    // Is there a maximum?
    if( max ){
      this.max = max;
    } else {
      this.max = -1;
    };
  }

window.close_user_success_popup = function(id) {
 $("#"+id).css("display", "none");
 window.location.reload();
}

window.close_add_user_success_popup = function(id) {
 $("#"+id).css("display", "none");
}

window.hideAttachmentSection = function(id) {
    var form_id = "#ck-delete-"+id
    const attachmentSection = $(form_id).closest(".attachment_section");
    const replyToTicketSection = $(form_id).closest(".reply_to_ticket");
    attachmentSection.css("display", "none");
    attachmentSection.prev().css("display","block");
    CKEDITOR.instances["editor_"+id].setData('')
    $('.files_attach_multi').empty();
    $("#attachment-field-take_"+id).val("")
    var attachment_length = $(".ticket_attachment_files").length
    for(var i=0; i<=(attachment_length); i++){
      // var attachment = $(".ticket_attachment_files")[i];
        // if (attachment.files.length > 0){
          var ele = $(".ticket_attachment_files")[i];
          if (ele != undefined){
            if (ele.files.length > 0){
              $(ele).remove()
            }
          }
      // }
    }
}

function NewMultiSelector( list_target, id, max=0 ){
  // Where to write the list
  this.list_target = list_target;
  this.eleId = id;
  // How many elements?
  this.count = 0;
  // How many elements?
  this.id = 0;
  // Is there a maximum?
  if( max ){
    this.max = max;
  } else {
    this.max = -1;
  };

  /**
   * Add a new file input element
   */
  this.addElement = function( element ){
    // Make sure it's a file input element
    if( element.tagName == 'INPUT' && element.type == 'file' ){

      // Element name -- what number am I?
      //element.name = 'file_' + this.id++;
      // if(element.getAttribute('data-type') === "msg"){
      //    // console.log(element.getAttribute('data-type'));
      //    // console.log(element);
      //     element.name = 'msg[ticket_attachments_attributes][][attachment]'
      // }else{
      //    // console.log(element.getAttribute('data-type'));
      //    // console.log(element);
          //element.name = 'ticket[notes_attributes][][ticket_attachments_attributes][][attachment]'
      // }

      // Add reference to this object
      element.multi_selector = this;

      // What to do when a file is selected
      element.onchange = function(){
      var new_element;
        for (var i = 0; i < element.files.length; i++) {
          // New file input
          new_element = document.createElement( 'input' );
          new_element.type = 'file';

              //var attach_ele = $('input[id=attachment-field]:visible')[0];
              // if(attach_ele.getAttribute('data-type') == "msg"){
              //     new_element.name = 'msg[ticket_attachments_attributes][][attachment]'
              //     $(new_element).attr('data-type', 'msg')
              // }else{
              //     element.name = 'ticket[notes_attributes][][ticket_attachments_attributes][][attachment]'
              // }
          if(element.name == "ticket_message_attachment_"+i){
            new_element.name = 'ticket_message_attachment_'+i;
            new_element.className = 'ticket_attachment_files'
          }
          else{
                new_element.name = 'ticket_message_attachment_'+i;
                new_element.className = 'ticket_attachment_files'
          }
              new_element.multiple = 'multiple';
              new_element.setAttribute("data-attachment-name", element.files[i].name);
              new_element.id = 'attachment-field' + '-' + element.files[i].name.split('.')[0] + "-" + i

          // Add new element
          // insertAfter( new_element, this );
          this.parentNode.insertBefore( new_element, this );

          // Apply 'update' to element
          this.multi_selector.addElement( new_element );

          // Update list
          this.multi_selector.addListRow( this, i );

          // Hide this: we can't use display:none because Safari doesn't like it
          // this.style.position = 'absolute';
          // this.style.left = '-1000px';
          // this.id = "attachment-field";
          // if (element.files.length > 1) {
          //  new_element.style.position = 'absolute';
          //  new_element.style.left = '-1000px';
          // } else {
            if (i == 0) {
              this.style.position = 'absolute';
              this.style.left = '-1000px';
            } else {
              new_element.style.position = 'absolute';
              new_element.style.left = '-1000px';
            }

          // }
        }
        // new_element = document.createElement( 'input' );
        // new_element.type = 'file';
        // new_element.name = 'ticket_message[ticket_attachments_attributes][][attachment]'
        // new_element.multiple = 'multiple'
        // new_element.id = 'attachment-field-take'
        this.parentNode.insertBefore( new_element, this );
        var file_list = [];
        var list_ele = $('div[id=files_list_take_'+id+']:visible')[0];

        // if (list_ele.children.length <= 0){
        //  list_ele = $('div[id=files_list]:visible')[0];
        // }

        // if (list_ele.children.length <= 0){
        //  list_ele = $('div[id=files_list_take_cus]:visible')[0];
        // }
        // if (list_ele.children.length <= 0){
        //  list_ele = $('div[id=files_list_1]:visible')[0];
        // }
        for (var i = 0; i < list_ele.children.length; i++) {
              file_list.push(list_ele.children[i].textContent);
        }
        $("#file_list_array").val(file_list);

      };
      // If we've reached maximum number, disable input element
      if( this.max != -1 && this.count >= this.max ){
        element.disabled = true;
      };

      // File element counter
      this.count++;
      // Most recent element
      this.current_element = element;

    } else {
      // This can only be applied to file input elements!
      // alert( 'Error: not a file input element' );
    };

  };

  /**
   * Add a new row to the list of files
   */
  this.addListRow = function( element, i ){

    // for (let i = 0; i < element.files.length; i++) {
      // Row div
       var new_row = document.createElement( 'div' );

      // // Delete button
      // var new_row_button = document.createElement( 'input' );
      // new_row_button.type = 'button';
      // new_row_button.value = 'Delete';

      // References
      new_row.element = element;

      // Delete function
      // new_row_button.onclick= function(){
      //  // Remove element from form
      //  // selected_input_value = document.getElementById('attachment-field-'+ element.files[i].name.split('.')[0] + "-" + i);
      //  // debugger
      //  selected_input_value = document.getElementById('attachment-field-'+ this.id);

      //  // deleteTicketAttachments(this, selected_input_value);

      //  // this.parentNode.element.parentNode.removeChild( this.parentNode.element );
      //  id_length = this.id.split("-").length - 1;
      //  if (this.id.split("-")[id_length] != "0"){
      //    this.parentNode.element.parentNode.removeChild( selected_input_value );
      //  }

      //  // Remove this row from the list
      //  this.parentNode.parentNode.removeChild( this.parentNode );

      //  // Decrement counter
      //  this.parentNode.element.multi_selector.count = this.parentNode.element.multi_selector.count - 1;

      //  // Re-enable input element (if it's disabled)
      //  this.parentNode.element.multi_selector.current_element.disabled = false;

      //  var file_list = [];

      //  var list_ele = $('div[id=files_list_take]:visible')[0];
      //  for (var i = 0; i < list_ele.children.length; i++) {
      //         file_list.push(list_ele.children[i].textContent);
      //  }
      //  $("#file_list_array").val(file_list);

      //  // Appease Safari
      //  //    without it Safari wants to reload the browser window
      //  //    which nixes your already queued uploads
      //  return false;
      // };

    // Set row value
      new_row.innerHTML = element.files[i].name;
      //new_row_button.id = element.files[i].name.split('.')[0] + "-" + i
      // Add button
      // new_row.appendChild( new_row_button );

      // Add it to the list
      this.list_target.appendChild( new_row );
    // }

  };

};

function deleteTicketAttachments(event, input) {
 event.parentElement.remove()
 var attachment_name = input.dataset['attachmentName']
 $('*[data-attachment-name="'+attachment_name+'"]').remove()
 // var file_name = input.id.split("attachment-field-")[1].split("-")[0];
 // var files = $("#attachment-field-take")[0].files;
 // var fileListArr = Array.from(files);
 // var file_list = [];
 // for (var i = 0; i < fileListArr.length; i++) {
 //        if (fileListArr[i].name.split('.')[0] == file_name){
 //         fileListArr.splice(i, 1);
 //     }
 // }
}

window.mapServiceAlert = function() {
  var ticket_owner_id = "";
  var current_url = window.location.href;
  var params = current_url.split("?")[1];
  if ((params != "") && (params != undefined) && (params != "dashboard_page")){
    ticket_owner_id = params.split("=")[1];
    if ((ticket_owner_id != undefined) && params.split("=").includes('ticket_owner_id')){
      window.location.href = "/grc/map-services?ticket_owner_id="+ticket_owner_id+"?map_service"
    }
    else{
      window.location.href = "/grc/map-services?" + "map_service"
    }
  }
  else{
    window.location.href = "/grc/map-services?" + "map_service"
  }
}

window.openMessages = function(msg_id){
  var payload = {};
  payload['id'] = $("#selected_organization_value").val();
  var step_name = getSectionName("dashboard_messages");
  var dashboard_key = $(".grc_dashboard_key").val();
  var url = '/grc/'+step_name+'/'+dashboard_key+'?msg_id='+msg_id;
  RenderNextComponent(payload, url);
}

window.messageNotification = function(event, msg_id){
  event.stopPropagation();
  openMessages(msg_id);
}

window.checkStatus = function(ticket_id){
  var selected_fin_year = $('#selected_fin_year').val();
  $.ajax({
    url: '/my_service_check_status',
    type: 'POST',
    dataType: "script",
    data: {ticket_id: ticket_id, selected_fin_year: selected_fin_year, method_type: "Get", method_name: "ticket_milestone_status", action_value: "ticket_milestone_status"},
  });
}

// window.renderCompliance = function(selectedType, selectedEntity,selectedDate) {
//   var organization_id = $('#selected_organization_value').val();
//   $.ajax({
//     url: '/dashboard_page?step=compliance_calendar',
//     type: 'GET',
//     dataType: "script",
//     data: { selected_type: selectedType, selected_entity: selectedEntity,
//       selected_date: selectedDate, organization_id: organization_id, method_type: "Get", method_name: "compliance_calender", action_value: "compliance_calender"},
//     success: function () {
//           setDueDates();
//           return true;
//     },
//     error: function(xhr,status,error){
//         return false;
//     }
//   });
// }

function setDueDates(){
  var due_date_exist = document.getElementById("due_date");
  if(due_date_exist){
    var due_date = document.getElementById("due_date").value;
    var on_date = document.getElementById("on_date").value;
    var modifyDuedate = []
    due_date.split(' ').forEach(e=>{
                           var temp = e.split('-');
                           var t1 = temp[1];
                           temp[1] = temp[2];
                           temp[2] = t1;
                           modifyDuedate.push(temp.reverse().join('/'))
                        })

    setTimeout(function(){
         modifyDuedate.forEach(e=>{
          const myNodelist = document.querySelectorAll("[data-day='"+ e +"']");
          for (let i = 0; i < myNodelist.length; i++) {
            myNodelist[i].classList.add('enabled');
            if(moment(new Date()).format("YYYY-MM-DD") > moment(e).format("YYYY-MM-DD")){
              myNodelist[i].classList.add('old-day');
            }
          }
         })
        if(on_date){
          var temp = on_date.split('-');
          var t1 = temp[1];
          temp[1] = temp[2];
          temp[2] = t1;
          on_date = temp.reverse().join('/');
          const myNodelist = document.querySelectorAll("[data-day='"+ on_date +"']");
          for (let i = 0; i < myNodelist.length; i++) {
            myNodelist[i].classList.add('selected-day');
          }
        }

    }, 1500);
  }
}

window.renderFilterCompliance = function(selectedType, selectedEntity,selectedDate) {
  var default_entity = document.getElementById("default_entity").value;
  if(((selectedEntity != default_entity && selectedEntity != '' )) || (selectedDate && selectedDate.startDate)){
    $('.dropdown-toggle.reset').css('display', 'block');
  }
  else{
    $('.dropdown-toggle.reset').css('display', 'none');
  }
  if(document.getElementById('currentFyView') && document.getElementById('currentFyView').checked){
    var selectedCalendarView = 'FV'
  }
  else if(document.getElementById('currentQuaterlyView') && document.getElementById('currentQuaterlyView').checked){
    var selectedCalendarView = 'QV'
  }
  if(document.getElementById('currentFyView') && document.getElementById('currentFyView').checked){
    var selectedFyYear = document.getElementById("currentFYearSelect").value;
  }
  else{
    var selectedFyYear = ""
  }
  var organization_id = $('#selected_organization_value').val();
  var legal_entity = selectedEntity ? selectedEntity : '';
  if(legal_entity.length == 1 && legal_entity[0] == "All"){
  	legal_entity = [ "All", "Private Limited Company (PVT)", "One Person Company (OPC)", "Limited Liability Partnership (LLP)", "Public Limited Company (public)", "Sole Proprietorship Firm", "Partnership", "Trust", "Society", "Section 8", "Foreign Company", "NBFC Company", "Nidhi Company", "Producer Company" ]
  }
  $.ajax({
    url: '/compliance_calendar_event',
    type: 'POST',
    dataType: "script",
    data: { selected_type: "calendarView", selected_entity: legal_entity,selected_date: selectedDate, organization_id: organization_id,calendar_type: selectedCalendarView, method_type: "Post", method_name: "compliance_calender", action_value: "compliance_calender", selected_fy_year: selectedFyYear},
    success: function () {
          setDueDates();
          return true;
    },
    error: function(xhr,status,error){
        return false;
    }
  });
}

window.renderEventBasedCompliance = function(selectedType, selectedEntity,selectedData) {
  var organization_id = $('#selected_organization_value').val();
  if(selectedData){
    $('#eventBasedDiv .dropdown-toggle.reset').css('display', 'block');
  }
  else{
    $('#eventBasedDiv .dropdown-toggle.reset').css('display', 'none');
  }
  var legal_entity = selectedData ? selectedData : '';
  $.ajax({
    url: '/compliance_calendar_event',
    type: 'POST',
    dataType: "script",
    data: { selected_type: "eventBased", selected_entity: "calendarView", selected_date: '', organization_id: organization_id, method_type: "Post", method_name: "compliance_calender", action_value: "compliance_calender", selected_entity: legal_entity},
  });
}

window.renderComplianceData = function(due_date) {
  var organization_id = $('#selected_organization_value').val();
  $.ajax({
    url: '/compliance_calendar_event',
    type: 'POST',
    dataType: "script",
    data: { due_date: due_date, organization_id: organization_id, method_type: "Post", method_name: "compliance_services", action_value: "compliance_services"},
    success: function () {
      return true;
    },
    error: function(xhr,status,error){
      return false;
    }
  });
  var params = commonTrackingDetails();
  if (due_date){
    vsTrackEvent({
      eventName: "date_selection",
      params: Object.assign(params, {
        source: "Compliance calendar",
        page_name: "Compliance calendar",
        selected_date: due_date
      })
    });
  } else {
    vsTrackEvent({
      eventName: "date_selection_removed",
      params: Object.assign(params, {
        source: "Compliance calendar",
        page_name: "Compliance calendar",
      })
    });
  }
}

window.getComplianceDate = function(selectedMonth, due_date, selected_date) {
  var organization_id = $('#selected_organization_value').val();
  $.ajax({
    url: '/compliance_calendar_event',
    type: 'POST',
    dataType: "script",
    data: { due_date: due_date, selected_date: selected_date, selected_month: selectedMonth, organization_id: organization_id, method_type: "Post", method_name: "compliance_services", action_value: "compliance_services"},
    beforeSend: function(){
      $("#overlayDiv").hide();
    },
    success: function () {
      return true;
    },
    error: function(xhr,status,error){
      return false;
    }
  });
}

// $('.interested_service').click(function(){
//   $("#overview_service").hide();
//   $("#compliance_calender_know_more").hide();
//   var service_id = $(this).attr('value')
//   LeadCreationPopup(service_id)
// });


// window.renderSupport = function() {
//   window.location.href = "/dashboard_page?step=contact_us_settings&close_popup=db_settings_list"
// }

window.closeStatusPopup = function(popupid) {
  $(popupid).css('display','none');
};

window.remarkPopup = function(remark){
  $("#remark-pop-content").html('');
  $("#remark-pop-content").append(remark)
  $("#remark-popup").css('display', 'block')
}

window.showPaymentpopup = function(popupId){
  $(popupId).css('display', 'block')
  $(popupId).addClass('show');
}

window.closePaymentpopup = function(popupId){
  $(popupId).css('display', 'none');
  $(popupId).removeClass('show');
}

window.renderSupport = function() {
  var dashboard_key = $(".grc_dashboard_key").val();
  var url = '/grc/support/'+dashboard_key;
  RenderNextComponent({}, url);
  // window.location.href = "/dashboard_page?step=contact_us_settings&close_popup=db_settings_list"
}

window.closeStatusPopup = function(popupid) {
  $(popupid).css('display','none');
};

window.renderBannerService = function(type) {
  if (type == "Active Services"){
    renderService('ongoing');
  } else if (type == "Pending Payments") {
    renderPayment('due');
  } else if (type == "Completed Services") {
    renderMyService('completed')
  }
}
