/**
 * Convert a single file-input element into a 'multiple' input list
 *
 * Usage:
 *
 *   1. Create a file input element (no name)
 *      eg. <input type="file" id="first_file_element">
 *
 *   2. Create a DIV for the output to be written to
 *      eg. <div id="files_list"></div>
 *
 *   3. Instantiate a MultiSelector object, passing in the DIV and an (optional) maximum number of files
 *      eg. var multi_selector = new MultiSelector( document.getElementById( 'files_list' ), 3 );
 *
 *   4. Add the first element
 *      eg. multi_selector.addElement( document.getElementById( 'first_file_element' ) );
 *
 *   5. That's it.
 *
 *   You might (will) want to play around with the addListRow() method to make the output prettier.
 *
 *   You might also want to change the line
 *       element.name = 'file_' + this.count;
 *   ...to a naming convention that makes more sense to you.
 *
 * Licence:
 *   Use this however/wherever you like, just don't blame me if it breaks anything.
 *
 * Credit:
 *   If you're nice, you'll leave this bit:
 *
 *   Class by Stickman -- http://www.the-stickman.com
 *      with thanks to:
 *      [for Safari fixes]
 *         Luis Torrefranca -- http://www.law.pitt.edu
 *         and
 *         Shawn Parker & John Pennypacker -- http://www.fuzzycoconut.com
 *      [for duplicate name bug]
 *         'neal'
 */
	function MultiSelector( list_target, max ){

		// Where to write the list
		this.list_target = list_target;
		// How many elements?
		this.count = 0;
		// How many elements?
		this.id = 0;
		// Is there a maximum?
		if( max ){
			this.max = max;
		} else {
			this.max = -1;
		};

		/**
		 * Add a new file input element
		 */
		this.addElement = function( element ){

			// Make sure it's a file input element
			if( element.tagName == 'INPUT' && element.type == 'file' ){

				// Element name -- what number am I?
				//element.name = 'file_' + this.id++;
	      // if(element.getAttribute('data-type') === "msg"){
	      //    // console.log(element.getAttribute('data-type'));
	      //    // console.log(element);
	      //     element.name = 'msg[ticket_attachments_attributes][][attachment]'
	      // }else{
	      //    // console.log(element.getAttribute('data-type'));
	      //    // console.log(element);
	          //element.name = 'ticket[notes_attributes][][ticket_attachments_attributes][][attachment]'
	      // }

				// Add reference to this object
				element.multi_selector = this;

				// What to do when a file is selected
				element.onchange = function(){

					// New file input
					var new_element = document.createElement( 'input' );
					new_element.type = 'file';

	        //var attach_ele = $('input[id=attachment-field]:visible')[0];
	        // if(attach_ele.getAttribute('data-type') == "msg"){
	        //     new_element.name = 'msg[ticket_attachments_attributes][][attachment]'
	        //     $(new_element).attr('data-type', 'msg')
	        // }else{
	        //     element.name = 'ticket[notes_attributes][][ticket_attachments_attributes][][attachment]'
	        // }
//					console.log(element.name);
					if(element.name == "ticket_message[ticket_attachments_attributes][][attachment]"){
						new_element.name = 'ticket_message[ticket_attachments_attributes][][attachment]'
					}
					else{
	        	new_element.name = 'msg[ticket_attachments_attributes][][attachment]'
					}

	        new_element.id = 'attachment-field'

					// Add new element
					this.parentNode.insertBefore( new_element, this );

					// Apply 'update' to element
					this.multi_selector.addElement( new_element );

					// Update list
					this.multi_selector.addListRow( this );

					// Hide this: we can't use display:none because Safari doesn't like it
					this.style.position = 'absolute';
					this.style.left = '-1000px';

				};
				// If we've reached maximum number, disable input element
				if( this.max != -1 && this.count >= this.max ){
					element.disabled = true;
				};

				// File element counter
				this.count++;
				// Most recent element
				this.current_element = element;

			} else {
				// This can only be applied to file input elements!
				// alert( 'Error: not a file input element' );
			};

		};

		/**
		 * Add a new row to the list of files
		 */
		this.addListRow = function( element ){

			// Row div
			var new_row = document.createElement( 'div' );

			// Delete button
			var new_row_button = document.createElement( 'input' );
			new_row_button.type = 'button';
			new_row_button.value = 'Delete';

			// References
			new_row.element = element;

			// Delete function
			new_row_button.onclick= function(){

				// Remove element from form
				this.parentNode.element.parentNode.removeChild( this.parentNode.element );

				// Remove this row from the list
				this.parentNode.parentNode.removeChild( this.parentNode );

				// Decrement counter
				this.parentNode.element.multi_selector.count--;

				// Re-enable input element (if it's disabled)
				this.parentNode.element.multi_selector.current_element.disabled = false;

				// Appease Safari
				//    without it Safari wants to reload the browser window
				//    which nixes your already queued uploads
				return false;
			};

			// Set row value
			new_row.innerHTML = element.value;

			// Add button
			new_row.appendChild( new_row_button );

			// Add it to the list
			this.list_target.appendChild( new_row );

		};

	};

function NewMultiSelector( list_target, max ){

	// Where to write the list
	this.list_target = list_target;
	// How many elements?
	this.count = 0;
	// How many elements?
	this.id = 0;
	// Is there a maximum?
	if( max ){
		this.max = max;
	} else {
		this.max = -1;
	};

	/**
	 * Add a new file input element
	 */
	this.addElement = function( element ){
		// Make sure it's a file input element
		if( element.tagName == 'INPUT' && element.type == 'file' ){

			// Element name -- what number am I?
			//element.name = 'file_' + this.id++;
      // if(element.getAttribute('data-type') === "msg"){
      //    // console.log(element.getAttribute('data-type'));
      //    // console.log(element);
      //     element.name = 'msg[ticket_attachments_attributes][][attachment]'
      // }else{
      //    // console.log(element.getAttribute('data-type'));
      //    // console.log(element);
          //element.name = 'ticket[notes_attributes][][ticket_attachments_attributes][][attachment]'
      // }

			// Add reference to this object
			element.multi_selector = this;

			// What to do when a file is selected
			element.onchange = function(){
			var new_element;
				for (var i = 0; i < element.files.length; i++) {
					// New file input
					new_element = document.createElement( 'input' );
					new_element.type = 'file';

			        //var attach_ele = $('input[id=attachment-field]:visible')[0];
			        // if(attach_ele.getAttribute('data-type') == "msg"){
			        //     new_element.name = 'msg[ticket_attachments_attributes][][attachment]'
			        //     $(new_element).attr('data-type', 'msg')
			        // }else{
			        //     element.name = 'ticket[notes_attributes][][ticket_attachments_attributes][][attachment]'
			        // }
					//console.log(element.name);
					if(element.name == "ticket_message[ticket_attachments_attributes][][attachment]"){
						new_element.name = 'ticket_message[ticket_attachments_attributes][][attachment]';
					}
					else{
	        			new_element.name = 'msg[ticket_attachments_attributes][][attachment]';
					}
	        		new_element.multiple = 'multiple';
	        		new_element.id = 'attachment-field' + '-' + element.files[i].name.split('.')[0] + "-" + i

					// Add new element
					// insertAfter( new_element, this );
					this.parentNode.insertBefore( new_element, this );

					// Apply 'update' to element
					this.multi_selector.addElement( new_element );

					// Update list
					this.multi_selector.addListRow( this, i );

					// Hide this: we can't use display:none because Safari doesn't like it
					// this.style.position = 'absolute';
					// this.style.left = '-1000px';
					// this.id = "attachment-field";
					// if (element.files.length > 1) {
					// 	new_element.style.position = 'absolute';
					// 	new_element.style.left = '-1000px';
					// } else {
						if (i == 0) {
							this.style.position = 'absolute';
							this.style.left = '-1000px';
						} else {
							new_element.style.position = 'absolute';
							new_element.style.left = '-1000px';
						}
						
					// }
				}
				// new_element = document.createElement( 'input' );
				// new_element.type = 'file';
				// new_element.name = 'ticket_message[ticket_attachments_attributes][][attachment]'
				// new_element.multiple = 'multiple'
				// new_element.id = 'attachment-field-take'
				this.parentNode.insertBefore( new_element, this );
				var file_list = [];
				var list_ele = $('div[id=files_list_take]:visible')[0];

				if (list_ele.children.length <= 0){
					list_ele = $('div[id=files_list]:visible')[0];
				}

				if (list_ele.children.length <= 0){
					list_ele = $('div[id=files_list_take_cus]:visible')[0];
				}
				if (list_ele.children.length <= 0){
					list_ele = $('div[id=files_list_1]:visible')[0];
				}
				for (var i = 0; i < list_ele.children.length; i++) {
			        file_list.push(list_ele.children[i].textContent);
				}
				$("#file_list_array").val(file_list);

			};
			// If we've reached maximum number, disable input element
			if( this.max != -1 && this.count >= this.max ){
				element.disabled = true;
			};

			// File element counter
			this.count++;
			// Most recent element
			this.current_element = element;

		} else {
			// This can only be applied to file input elements!
			// alert( 'Error: not a file input element' );
		};

	};

	/**
	 * Add a new row to the list of files
	 */
	this.addListRow = function( element, i ){

		// for (let i = 0; i < element.files.length; i++) {
			// Row div
			var new_row = document.createElement( 'div' );

			// Delete button
			var new_row_button = document.createElement( 'input' );
			new_row_button.type = 'button';
			new_row_button.value = 'Delete';

			// References
			new_row.element = element;

			// Delete function
			new_row_button.onclick= function(){
				// Remove element from form
				// selected_input_value = document.getElementById('attachment-field-'+ element.files[i].name.split('.')[0] + "-" + i);
				// debugger
				selected_input_value = document.getElementById('attachment-field-'+ this.id);

				// deleteTicketAttachments(this, selected_input_value);

				// this.parentNode.element.parentNode.removeChild( this.parentNode.element );
				id_length = this.id.split("-").length - 1;
				if (this.id.split("-")[id_length] != "0"){
					this.parentNode.element.parentNode.removeChild( selected_input_value );
				}

				this.parentNode.element.parentNode.removeChild( this.parentNode.element );

				// Remove this row from the list
				this.parentNode.parentNode.removeChild( this.parentNode );

				// Decrement counter
				this.parentNode.element.multi_selector.count = this.parentNode.element.multi_selector.count - 1;

				// Re-enable input element (if it's disabled)
				this.parentNode.element.multi_selector.current_element.disabled = false;

				var file_list = [];

				var list_ele = $('div[id=files_list_take]:visible')[0];
				for (var i = 0; i < list_ele.children.length; i++) {
			        file_list.push(list_ele.children[i].textContent);
				}
				$("#file_list_array").val(file_list);

				// Appease Safari
				//    without it Safari wants to reload the browser window
				//    which nixes your already queued uploads
				return false;
			};

		// Set row value
			new_row.innerHTML = element.files[i].name;
			new_row_button.id = element.files[i].name.split('.')[0] + "-" + i
			// Add button
			new_row.appendChild( new_row_button );

			// Add it to the list
			this.list_target.appendChild( new_row );
		// }

	};

};

window.NewMultiSelector = NewMultiSelector

function insertAfter(newNode, existingNode) {
    existingNode.parentNode.insertBefore(newNode, existingNode.nextSibling);
}

// function deleteTicketAttachments(event, input) {
// 	var file_name = input.id.split("attachment-field-")[1].split("-")[0];
// 	var files = $("#attachment-field-take")[0].files;
// 	var fileListArr = Array.from(files);
// 	var file_list = [];
// 	for (var i = 0; i < fileListArr.length; i++) {
//         if (fileListArr[i].name.split('.')[0] == file_name){
//         	fileListArr.splice(i, 1);
// 	    }
// 	}
// }

