// require("jquery")
// require("jquery-ui")
import "jquery"
import $ from "jquery"
require("parsleyjs")
// import autocomplete from 'autocomplete.js/dist/autocomplete.jquery.js';

window.isValidEmailPhone = function(evt, event) {
    evt = (evt) ? evt : window.event;
	var charCode = window.event.keyCode;
	var keyChar = String.fromCharCode(charCode);
	var class_name = event.className.split(" ")[1];
    if ((keyChar == "") || (keyChar == undefined)){
	   $("."+class_name).attr('data-parsley-required', false);
	}
	else{
	   $("."+class_name).attr('data-parsley-required', true);
	}
	return true;
}

window.enableValidation = function(event) {
  var mobile_number = event.value;
  var class_name = event.className.split(" ")[1];
  if (mobile_number.length >= 1){
    $("."+class_name).attr('data-parsley-required', true);
  }
  else{
	$("."+class_name).attr('data-parsley-required', false);
  }
  var form_name = event.closest('form').id;

  if($("#"+form_name).parsley().validate() == true){
  	$("#"+form_name).find('.submit_button').removeAttr('disabled')
  	$("#"+form_name).find('.cancel_button').removeAttr('disabled')
  }
  else{
  	$("#"+form_name).find('.submit_button').attr('disabled', 'disabled')	
  	$("#"+form_name).find('.cancel_button').removeAttr('disabled')
  }
  return true;
}

window.accountDelete = function(id) {
  if ($(".choose_account-"+id).css('display') == "none"){
    $(".choose_account-"+id).css('display','block');
  }
  else{
    $('.choose_account-'+id).css('display','none');
  }
}

window.deleteUserAccount = function(popupid, value_selected){
  $(popupid).addClass('show');
  $(popupid+" input").val(value_selected);
  $('.choose_account-'+value_selected).css('display','none');
}

window.closePopupModel = function(popupId){
  $(popupId).hide();
  $(popupId).removeClass('show');
}

window.confirmDeleteEntity = function() {
  var organization_id = $(".organization_id").val();
  var header = {};
  header["key"] = $('#api_key').attr('value');
  header["token"] = $('.authentication_token').attr('value');
  var vs_api_url = $('.environment_url').attr('value');
  var url = vs_api_url+"/api/v1/delete_entity/"+organization_id
  $.ajax({
    type: "delete",
    dataType: "json",
    url: url,
    headers: {key: header["key"], token: header["token"] },
    beforeSend: function(){
      $("#overlayDiv").show();
    },
    success: function (data) {
      $("#overlayDiv").hide();
      window.location.reload();
    },
    error: function(xhr,status,error){
      return false;
    }
  });
}

$(document).ready(function(){
	window.confirmReview = function(element) { 
		element.closest('.grc_popup').style.display = 'none'
	}
	window.openHelpPopup = function(){
		$(".open_help_content_popup").click(function(){
			$("#help_popup").css("display", "block");
		});
	}

	openHelpPopup();
	function AddOrganization(){
		BusinessType();
		SearchCompanyName();
		// SubmitAction();
		Select2Calls();
		IdentifierType();
		logoUpload();
		openHelpPopup();
		$(".close_confirmation_popup").click(function(){
           $(this).closest(".password-popupoverlay").css("display", "none");
		});
		$(".choose_account_redirect").click(function(){
			var url = $(this).attr('value');
			var current_url = window.location.href;
			var params = current_url.split("?")[1];
			if ((params != "") && (params != undefined)){
				window.location.href = window.location.origin+url+"?"+params
			}
			else{
				window.location.href = window.location.origin+url
			}
		});
	}

	window.addOrgBack = function(){
		var id = $('#organization_id').attr('value');
			$.ajax({
        type: "post",
        dataType: "script",
        url: '/organization/remove_organization',
        data: {organization_id: id},
      });
		var current_url = window.location.href;
		var dashboard_key = $(".grc_dashboard_key").val();
		if ((current_url != "") && (current_url != undefined)){
			if (current_url.includes("dashboard_page")){
				window.location.href = window.location.origin+"/grc/dashboard/"+dashboard_key
			}else if(current_url.includes("choose_account")){
				window.location.href = window.location.origin+"/grc/choose-account"
			}else if(current_url.includes("map_service")){
        window.location.href = window.location.origin+"/grc/map-services"
			}else {
				window.location.href = window.location.origin+"/grc/dashboard/"+dashboard_key
			}
		}
	}

	function BusinessType(){
		$('.add_organisation_form .business_type').change(function(){
			var id = $('#organization_id').attr('value');
			if ((id == undefined) || (id == '')){
				var value = $(this).val();
				var payload = {};
				var id = $('#organization_id').attr('value');
				payload['tab_identifier'] = 'add_organisation'
				payload['attributes'] = {};
				payload['attributes']['business_type'] = value;
				payload['attributes']['id'] = id;
				payload['email'] = "";
				var current_url = window.location.href;
				var params = current_url.split("?")[1];
				if ((params != "") && (params != undefined)){
					payload['email'] = params.split("=")[1];
				}
				// var response = get_vs_api_data('/api/v1/grc/organizations?tab_identifier=add_organisation', payload)
				$.ajax({
				type: "post",
				dataType: "script",
				url: '/grc/add-organization?step=1',
				data: payload,
				success: function (data) {
					// GetVsApiUrl();
					SearchCompanyName();
					BusinessType();
					IdentifierType();
					AddOrganization();
					return true;
				},
				error: function(xhr,status,error){
					return false;
				}
				});
			}
			else{
				var value = $(this).val();
				$("#confirm_org_business_type").css("display", "block");
				$("#confirm_org_business_type .change_org_business_type").val(value);
				changeOrganizationBusinessType();
				closeOrganizationPopup();
			}
		});
	}

	function IdentifierType(){
		$('.add_organisation_form .identifier_type').change(function(){
			var id = $('#organization_id').attr('value');
			if ((id != undefined) || (id != '')){
				var value = $(this).val();
				var payload = {};
				var id = $('#organization_id').attr('value');
				payload['tab_identifier'] = 'add_organisation'
				payload['attributes'] = {};
				payload = BuildPayload(payload)
				payload['attributes']['id'] = id;
				payload['email'] = "";
				var current_url = window.location.href;
				var params = current_url.split("?")[1];
				if ((params != "") && (params != undefined)){
					payload['email'] = params.split("=")[1];
				}
				$.ajax({
				type: "post",
				dataType: "script",
				url: '/grc/add-organization?step=1',
				data: payload,
				success: function (data) {
					AddOrganization();
					return true;
				},
				error: function(xhr,status,error){
					return false;
				}
				});
			}
		});
	}

	function changeOrganizationBusinessType(){
		$('.change_org_business_type').click(function(){
			var value = $(this).val();
			var payload = {};
			var id = $('#organization_id').attr('value');
			payload['tab_identifier'] = 'add_organisation'
			payload['attributes'] = {};
			payload['attributes']['business_type'] = value;
			payload['attributes']['id'] = id;
			payload['email'] = "";
			var current_url = window.location.href;
			var params = current_url.split("?")[1];
			if ((params != "") && (params != undefined)){
				payload['email'] = params.split("=")[1];
			}
			// var response = get_vs_api_data('/api/v1/grc/organizations?tab_identifier=add_organisation', payload)
			$.ajax({
	          type: "post",
	          dataType: "script",
	          url: '/grc/add-organization?step=1',
	          data: payload,
	          success: function (data) {
				$("#confirm_org_business_type").css("display", "none");
	          	// GetVsApiUrl();
	          	SearchCompanyName();
				BusinessType();
				AddOrganization();
	            return true;
	          },
	          error: function(xhr,status,error){
	            return false;
	          }
	        });
		});
	}

	function closeOrganizationPopup(){
		$(".cancel_org_business_type").click(function(){
			var payload = {};
			var id = $('#organization_id').attr('value');
			payload['tab_identifier'] = 'add_organisation'
			payload['attributes'] = {};
			payload['attributes']['id'] = id;
			payload['email'] = "";
			var current_url = window.location.href;
			var params = current_url.split("?")[1];
			if ((params != "") && (params != undefined)){
				payload['email'] = params.split("=")[1];
			}
			// var response = get_vs_api_data('/api/v1/grc/organizations?tab_identifier=add_organisation', payload)
			$.ajax({
	          type: "post",
	          dataType: "script",
	          url: '/grc/add-organization?step=1',
	          data: payload,
	          success: function (data) {
				$("#confirm_org_business_type").css("display", "none");
	          	// GetVsApiUrl();
	          	SearchCompanyName();
				BusinessType();
				AddOrganization();
	            return true;
	          },
	          error: function(xhr,status,error){
	            return false;
	          }
	        });
		});
	}

	BusinessType();

	function GetVsApiUrl(){
		$.ajax({
          type: "get",
          dataType: "json",
          url: '/get_vs_api_url',
          data: ''
     	});
		return 'http://localhost:3001'
	}


	function SearchCompanyName(){
		var business_types = ["pvt","opc","section_8", "llp","foreign","nbfc","nidhi","producer"];
		var current_business_type = $('.business_type').val();
		var header = {};
		// header["key"] = "e7e7a52397d57a5cd126328db7793009";
		// header["token"] = $('.authentication_token').attr('value');
 	    var vs_api_url = $('.environment_url').attr('value');
		var url = vs_api_url+"/api/v1/grc/company_name_lookup";
		if (business_types.includes(current_business_type)){
			$(".identification_number").attr('readonly', true);
			$('.business_name').select2({
	    	placeholder: 'Please enter 3 or more characters',
		    minimumInputLength: 3,
		    ajax: {
		      type: "POST",
		      dataType: "json",
		      url: '/get_grc_ajax_data',
		      delay: 500,
		      data: function(params){
		      	var keyword = params.term;
		      	var payload = {}
		      	payload['attributes'] = {};
				payload['attributes']['name'] = keyword;
				payload['attributes']['business_type'] = current_business_type;
				var response_data = {};
				response_data["payload"] = payload;
				response_data["url"] = url;
				return response_data;
		      },
		      processResults: function(data, params) {
		      	Select2ClickeEvent()
				SubmitAction();
		      	var length = data.length
		      	var myResults;
	          myResults = void 0;
	          myResults = []
	          $.each(data , function(index, val) {
	            myResults.push({
	              id: val['cin_no'],
	              text: val['company_name']
	          });
	          });
	          return {
	            results: myResults
	          };
		      }
		    }
		  });
		}
		else{
			SubmitAction();
		}
	}

	function Select2ClickeEvent(){
		// alert("hi3");
		$('.business_name').on('select2:select', function (e) {
			var text = $(this).find(":selected").text();
			var value = $(this).find(":selected").val();
			//console.log("Company Name :"+text)
			//console.log("CIN :"+value)
			if (value == 'NIL'){
		  		var value = $(this).val();
					var payload = {};
					var id = $('#organization_id').attr('value');
					payload['table_name'] = $('#table_name').attr('value');
					payload['tab_identifier'] = "add_organisation";
					payload['attributes'] = {};
					payload['attributes']['id'] = id;
					payload = BuildPayload(payload)
					payload['attributes']['is_company_name_editable'] = true;
					payload['email'] = "";
					var current_url = window.location.href;
					var params = current_url.split("?")[1];
					if ((params != "") && (params != undefined)){
						payload['email'] = params.split("=")[1];
					}
					$.ajax({
			          type: "post",
			          dataType: "script",
			          url: '/grc/add-organization?step=1',
			          data: payload,
			          success: function (data) {
						BusinessType();
						SubmitAction();
		            	return true;
		          	},
		          error: function(xhr,status,error){
		            return false;
		          }
		        });
			}
			else{
				var id = $(".identification_number").attr("data-parsley-id");
				$("#parsley-id-"+id).hide();
				$(".identification_number").val(value);
			}
 	  });
	}

	function GetMandatoryFields(){
		var data = $('.form-control')
		var fields_ids_to_validate =[]
		for(var i=0; i<=(data.length - 1); i++){
			if (!(data[i].attributes['data-parsley-required'] == undefined))
			{
				if (data[i].attributes['data-parsley-required'].value == 'true'){
					fields_ids_to_validate.push(data[i].attributes['name'].value)
				}
			}
		}
	return fields_ids_to_validate;
	}

	function Select2Calls(){
		// $('.select2').select2({
	 //      width: '360px'
	 //    });
	    
	    $('.sub_industry').select2({
	    	placeholder: 'Select Sub Industry',
	    	maximumSelectionLength: 10
	    });
	    
		$('.pincode').select2({
		    placeholder: 'Please enter 3 or more characters',
		    minimumInputLength: 3,
		    ajax: {
		      type: "get",
		      dataType: "script",
		      url: "/get_pincode_details",
		      delay: 500,
		      data: function(params){
		        var ticket_id = "1142536";
		        params.m = this
		        return{
		          pincode: params.term, ticket_id: ticket_id
		        };
		      },
		      processResults: function(data, params) {
		          var myResults;
		          myResults = void 0;
		          myResults = []
		          $.each(JSON.parse(data) , function(index, val) {
		            myResults.push({
		              id: val.id.split(" -")[0],
		              text: val.name
		            });
		          });
		          return {
		            results: myResults
		          };
		      }
		    }
		  });


		  $('.pincode').on('select2:select', function (e) {
		    var data = e.params.data;
		    var $t = $(this);
		    var pincode_details = data["text"].split("-")[1].split(", ");
		    var $select_city = $t.parent().parent().find('.city');
		    var $select_country = $t.parent().parent().find('.country');
		    var $select_state = $t.parent().parent().find('.state');
		    var $select_district = $t.parent().parent().find('.district');
		    $select_state.val(pincode_details[2]);
		    $select_district.val(pincode_details[1]);
		    $select_city.val(pincode_details[0]);
		    $select_country.val("India");
		    var form = $t.closest('form').attr("id");
		    $("#"+form).parsley().validate();
		  });
	}

	function SubmitAction(){
		$('.submit_button').click(function(){
			var url = $(this).attr('action')
			var success = false;
			var mandatory_values = [];
			if (($(this).text().trim() == 'Continue') || ($(this).text().trim() == 'Save')){
				var mandatory_fields = GetMandatoryFields();
				if (mandatory_fields.length > 0){
					for(var i=0; i<=(mandatory_fields.length - 1); i++){
						if ($("."+mandatory_fields[i]).parsley().validate() == true){
							mandatory_values.push(true);
						}
						else{
							mandatory_values.push(false);
						}
					}
					if (mandatory_values.includes(false)){
						success = false;
					}
					else{
						success = true;	
					}
				}
				else{
					success = true;
				}	
			}
			else{
				success = true
			}
			if (success == true)
			{
				// var url = 'http://localhost:3001/api/v1'+url
				var vs_api_url = $('.environment_url').attr('value');
				var url = vs_api_url+'/api/v1'+url
				var payload = {};
				var id = $('#organization_id').attr('value');
				payload['table_name'] = $('#table_name').attr('value');
				payload['attributes'] = {};
				payload['attributes']['id'] = id;
				payload['email'] = "";
				payload['prev_page'] = "";
				var current_url = window.location.href;
				var params = current_url.split("?")[1];
				if ((current_url != "") && (current_url != undefined)){
					if (current_url.includes("dashboard_page")){
						payload['prev_page'] = "dashboard_page"
					}else if(current_url.includes("choose_account")){
						payload['prev_page'] = "choose_account"
					}else if(current_url.includes("map_service")){
            payload['prev_page'] = "map_service"
					}else {
						payload['prev_page'] = "";
					}
				}
				if ((params != "") && (params != undefined)){
					payload['email'] = params.split("=")[1];
				}
				if (($(this).attr('value') == 'continue') || ($(this).attr('value') == 'save')){
					payload = BuildPayload(payload)
				}
				var header = {};
				// header["key"] = "e7e7a52397d57a5cd126328db7793009";
				// header["token"] = $('.authentication_token').attr('value');
				SubmitData(payload, url, header);
			}
			else{
				return false;	
			}
		});
		$('.grc_skip_button').click(function(){
			var url = $(this).attr('action')
			var current_url = window.location.href;
			var params = current_url.split("?")[1];
			if ((params != "") && (params != undefined)){
				window.location.href = window.location.origin+url+"?"+params
			}
			else{
				window.location.href = window.location.origin+url
			}
		});
	}

	function logoUpload(){
		$("#logo_upload").click(function(){
			$("#logo_document_upload").trigger("click");
		});

		$("#logo_document_upload").change(function(){
		   var attachment = this.files[0];
			if ((attachment.size <= 20971520) && ["jpg","jpeg","png"].includes(attachment.type.split("/")[1])){
				var formData = new FormData();
				formData.append('id', $(this).attr('value'));
				formData.append('document_key', $("#document_key").attr('value'));
				formData.append('document', attachment, attachment.name);
				var email = "";
				var current_url = window.location.href;
				var params = current_url.split("?")[1];
				if ((params != "") && (params != undefined)){
					email = params.split("=")[1];
				}
				formData.append('email', email);
				$.ajax({
					type: "post",
					dataType: "script",
					processData: false,
          contentType: false,
					url: "/upload_logo_document",
					data: formData,
					success: function (data) {
						logoUpload();
					},
					error: function(xhr,status,error){
					}
				});
			}
			else{
				if ((attachment.size > 20971520) && ["jpg","jpeg","png"].includes(attachment.type.split("/")[1])){
					alert("Invalid file size, not more than 20MB is allowed");
				}
				else{
					alert("Invalid image type or unsupported image format");
				}
			}
		});
	}

	function SubmitData(payload, url, header){
		//console.log("URL"+url)
		//console.log("PAYLOAD"+payload)
		$.ajax({
      type: "post",
      dataType: "json",
	  url: '/get_grc_ajax_data',
	  data: {payload: payload, url: url},
      success: function (data) {
		if (data['data'][0]['next_action'] != undefined){
           renderModule(data['data'][0]['next_action'])
		}
		else{
			var component_name = data['data'][0]['table_identifier']['tab_identifier']
			var id = data['data'][0]['table_identifier']['id']
			RenderNextComponent(data)
		}
      },
      error: function(xhr,status,error){
      }
    });
	}

	function BuildPayload(payload){
		if ($("#tab_identifier").attr('value') == 'add_organisation'){
			payload['attributes']['business_type'] = $('.business_type').val();
			if ($('.business_name').is('select') == true){
				// payload['attributes']['business_name'] = $('.business_name').text().split('Select Business Name')[1];
				payload['attributes']['business_name'] = $('.business_name').find(":selected").text();
			}
			else{
				payload['attributes']['business_name'] = $('.business_name').val();
			}
			payload['attributes']['identifier_type'] = $('.identifier_type').val();
			payload['attributes']['identification_number'] = $('.identification_number').val();
		}
		else if ($("#tab_identifier").attr('value') == 'organisation_detail'){
			payload['attributes']['business_industry'] = $('.business_industry').val();
			payload['attributes']['sub_industry'] = $('.sub_industry').val();
			payload['attributes']['inc_date'] = $('.inc_date').val();
		}
		else if ($("#tab_identifier").attr('value') == 'organisation_address'){
			payload['attributes']['flat_no'] = $('.flat_no').val();
			payload['attributes']['street'] = $('.street').val();
			payload['attributes']['pincode'] = $('.pincode').val();
			payload['attributes']['city'] = $('.city').val();
			payload['attributes']['district'] = $('.district').val();
			payload['attributes']['state'] = $('.state').val();
			payload['attributes']['country'] = $('.country').val();
			payload['attributes']['phone_number'] = $('.phone_number').val();
			payload['attributes']['e_mail'] = $('.e_mail').val();
			payload['attributes']['organization_id'] = $('.organization_id').val();
		}
		return payload;
	}

	function RenderNextComponent(data){		
		$.ajax({
      type: "post",
      dataType: "script",
      url: '/add_organization_render_component?step=1',
      data: {response: JSON.stringify(data)},
      success: function (data) {
      	AddOrganization();
      },
      error: function(xhr,status,error){
      }
    });
	}

	function renderModule(next_component){
		window.location.href = window.location.origin+next_component
	}

	// function RenderNextComponent(component_name, id){
	// 	var payload = {};
	// 	payload['tab_identifier'] = component_name
	// 	payload['attributes'] = {};
	// 	payload['attributes']['id'] = id;
	// 	$.ajax({
 //      type: "post",
 //      dataType: "script",
 //      url: '/add_organization_render_component?step=1',
 //      data: payload,
 //      success: function (data) {
 //      	AddOrganization();
 //      },
 //      error: function(xhr,status,error){
 //      }
 //    });
	// }
	
});
	